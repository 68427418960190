import { CSSProperties } from 'react';
import { CaretRightFilled } from '@ant-design/icons';
import { Button } from '@jll/react-ui-components';

import { enterPresentationMode } from 'store/applicationModeSlice';
import { useAppDispatch } from 'types/hooks';

interface EnablePresentationModeButtonProps {
    style?: CSSProperties;
    icon?: React.ReactElement | null;
    children?: React.ReactNode;
    className?: string;
}
export default function EnablePresentationModeButton({
    style,
    icon = <CaretRightFilled />,
    children,
    className,
}: EnablePresentationModeButtonProps): JSX.Element {
    const dispatch = useAppDispatch();

    return (
        <Button
            className={className}
            style={style}
            title='Enable Presentation Mode'
            icon={icon}
            onClick={() => {
                dispatch(enterPresentationMode());
            }}
        >
            {children}
        </Button>
    );
}
