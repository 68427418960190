enum SharedState {
    All = 0,
    Owner = 1,
    Public = 2,
    SharedWithMe = 3,
    Favorite = 4,
}

export const SHARED_STATE_STRINGS: SharedStateString[] = [
    'All',
    'Owner',
    'Public',
    'SharedWithMe',
    'Favorite',
];

export type SharedStateString = keyof typeof SharedState;

export default SharedState;
