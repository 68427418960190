import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from '@jll/react-ui-components';

import { savePresentation } from 'store/presentationSlice';
import { useAppDispatch } from 'types/hooks';

const usePresentationActions = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const app = App.useApp();

    const handleSave = useCallback(
        async function (presentationId: number, presentationName: string) {
            await dispatch(savePresentation({ presentationId, presentationName, navigate, app }));
        },
        [app, dispatch, navigate]
    );

    return {
        savePresentation: handleSave,
    };
};

export default usePresentationActions;
