import SceneView from '@arcgis/core/views/SceneView';
import Sketch from '@arcgis/core/widgets/Sketch';

let sketchViewModelInstance: Sketch | null = null;

export const setSketchWidget = (view: SceneView) => {
    if (!sketchViewModelInstance) {
        sketchViewModelInstance = new Sketch({
            view: view,
        });
    }
};

export const getSketchViewModel = () => {
    return sketchViewModelInstance;
};
