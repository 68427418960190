import { useState } from 'react';
import { Form, Modal } from '@jll/react-ui-components';

import { MarketDetail } from 'api/marketApi';
import MarketAccessForm from './MarketAccessForm';
import MarketDetailEntryForm from './MarketDetailEntryForm';

const ModalTitle: { [char: string]: string } = {
    edit: 'Edit Market',
    access: 'Market Access',
    add: 'Add Market',
    '': '',
};

interface MarketDetailsEntryDialogProps {
    market: MarketDetail;
    lastAction: string;
    closeDialog: () => void;
}

const MarketDetailsEntryDialog = ({
    market,
    lastAction,
    closeDialog,
}: MarketDetailsEntryDialogProps) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);

    const handleOk = () => {
        form.submit();
    };

    const title = ModalTitle[lastAction || ''];

    return (
        <Modal
            title={title}
            open={true}
            onCancel={closeDialog}
            onOk={handleOk}
            afterClose={() => {
                setSaving(false);
            }}
            okButtonProps={{ loading: saving }}
            styles={{ body: { overflow: 'auto', maxHeight: '72vh' } }}
            destroyOnClose={true}
            style={{ top: '8vh' }}
            mask={false}
        >
            {lastAction !== 'access' && (
                <MarketDetailEntryForm
                    form={form}
                    action={lastAction || ''}
                    market={market || {}}
                    onSaveSuccess={closeDialog}
                    setSaving={setSaving}
                />
            )}
            {lastAction === 'access' && (
                <MarketAccessForm
                    form={form}
                    market={market}
                    onSaveSuccess={closeDialog}
                    setSaving={setSaving}
                />
            )}
        </Modal>
    );
};

export default MarketDetailsEntryDialog;
