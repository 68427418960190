import { CSSProperties, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import type { ModalProps } from '@jll/react-ui-components';
import { Modal } from '@jll/react-ui-components';

import useTheme from 'themes/useTheme';

import styles from './DraggableModal.module.scss';

interface DraggableModalPosition {
    x: number;
    y: number;
}

interface DraggableModalDimensions {
    width?: number;
    height?: number;
}
interface DraggableModalProps extends ModalProps {
    title?: string | React.ReactNode;
    children?: React.ReactNode;
    position?: DraggableModalPosition;
    dimensions?: DraggableModalDimensions;
    headerStyle?: CSSProperties;
}

const DraggableModal = ({
    title,
    children,
    position,
    dimensions,
    headerStyle,
    ...props
}: DraggableModalProps) => {
    const { padding } = useTheme();
    const draggleRef = useRef<HTMLDivElement>(null);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });

    const modalPosition =
        position && position.x != null && position.y != null ? position : undefined;
    const modalWidth = (dimensions?.width && `${dimensions.width}px`) ?? undefined;

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        if (draggleRef.current) {
            const targetRect = draggleRef.current.getBoundingClientRect();
            if (!targetRect) {
                return;
            }
            setBounds({
                left: -targetRect.left + uiData.x,
                right: clientWidth - (targetRect.right - uiData.x),
                top: -targetRect.top + uiData.y,
                bottom: clientHeight - (targetRect.bottom - uiData.y),
            });
        }
    };

    const ModalHeader = () => {
        return (
            <div style={{ width: '100%', paddingBottom: 8, ...headerStyle, position: 'relative' }}>
                <div
                    style={{
                        position: 'absolute',
                        top: -padding - 4,
                        left: -padding - 8,
                        right: -padding - 8,
                        bottom: 0,
                        background: 'red',
                        zIndex: 10,
                        opacity: 0,
                    }}
                ></div>
                {title}
            </div>
        );
    };

    return (
        <Modal
            title={<ModalHeader />}
            width={modalWidth}
            mask={props.mask ?? false}
            className={styles.draggableModal}
            modalRender={(modal) => (
                <Draggable
                    bounds={bounds}
                    defaultPosition={modalPosition}
                    onStart={(event, uiData) => onStart(event, uiData)}
                    handle='.ant-modal-header'
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
            {...props}
        >
            {children}
        </Modal>
    );
};

export default DraggableModal;
