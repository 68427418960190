import { useEffect, useState } from 'react';
import { Table } from '@jll/react-ui-components';

import { MarketDetail } from 'api/marketApi';
import { fetchMarketDetails, selectMarketDetailedList } from 'store/marketListSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import MarketListActions from './MarketListActions';

import styles from './MarketList.module.scss';

const columns = [
    {
        title: 'Market Name',
        dataIndex: 'marketName',
        key: 'name',
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: 150,
        render: (_text: string, record: MarketDetail) => <MarketListActions record={record} />,
    },
];

interface MarketListProps {
    searchText?: string;
}

const MarketList = ({ searchText }: MarketListProps) => {
    const dispatch = useAppDispatch();
    const markets = useAppSelector(selectMarketDetailedList);
    const [data, setData] = useState<MarketDetail[]>([]);

    useEffect(() => {
        if (markets === null) dispatch(fetchMarketDetails());
    }, [dispatch, markets]);

    useEffect(() => {
        setData(
            markets?.filter((market) =>
                market.marketName.toLowerCase().includes(searchText?.toLowerCase() || '')
            ) || []
        );
    }, [markets, searchText]);
    return (
        <>
            <Table
                dataSource={data}
                columns={columns}
                className={styles.marketsList}
                pagination={false}
                rowKey='id'
                sticky={true}
                scroll={{ y: 'calc(100vh - 200px)' }}
            />
        </>
    );
};

export default MarketList;
