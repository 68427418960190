import React, { Suspense } from 'react';

import { FullScreenSpinner } from 'components/FullScreenSpinner';
import { MapProvider } from 'hooks/MapProvider';
const LazyMap = React.lazy(() => import('pages/MapContainer'));

const Map = ({ dataProvider }: { dataProvider?: string }) => {
    return (
        <Suspense fallback={<FullScreenSpinner />}>
            <MapProvider>
                <LazyMap dataProvider={dataProvider} />
            </MapProvider>
        </Suspense>
    );
};

export default Map;
