import { ReactElement, useEffect, useState } from 'react';
import { Spin, Timeline, Typography } from '@jll/react-ui-components';
import formatDistance from 'date-fns/formatDistance';

import GridItem, { GridItemImagePosition } from 'components/GridItem';
import FeaturedPresentation from 'interfaces/FeaturedPresentation';
import {
    fetchFeaturedPresentations,
    selectFeaturedPresentations,
} from 'store/featuredPresentationsSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import { getPresentationUrl } from 'utils/apiClient/urlUtil';
import { emailToDisplayName } from 'utils/emailUtils';
import EmptyPresentationList from './EmptyPresentationList';
import PresentationImage from './PresentationImage';

import styles from './FeaturedPresentations.module.scss';

interface FeaturedPresentationListProps {
    featuredPresentations: FeaturedPresentation[] | undefined;
}

export default function FeaturedPresentations({ ...others }): ReactElement {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const featuredPresentations = useAppSelector(selectFeaturedPresentations);

    useEffect(() => {
        const getFeaturedPresentations = async () => {
            try {
                await dispatch(fetchFeaturedPresentations());
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        getFeaturedPresentations();
    }, [dispatch]);

    const content = loading ? (
        <Spin style={{ marginTop: '32px' }} />
    ) : (
        <FeaturedPresentationsList featuredPresentations={featuredPresentations} />
    );

    return (
        <div {...others} className={styles.container}>
            <Typography.Title className={styles.header} style={{ fontWeight: 400 }} level={4}>
                Featured Content
            </Typography.Title>
            {content}
        </div>
    );
}

function FeaturedPresentationsList({
    featuredPresentations,
}: FeaturedPresentationListProps): ReactElement {
    if (featuredPresentations) {
        const presentationsTemplate = featuredPresentations.map(
            ({
                presentationId,
                presentationName,
                marketName,
                createdBy,
                modifiedDate,
                clientVersion,
            }: FeaturedPresentation) => {
                const description = (
                    <>
                        {emailToDisplayName(createdBy)}
                        <span> &bull; </span>
                        {formatDistance(new Date(modifiedDate), new Date())}
                    </>
                );
                return {
                    key: presentationId,
                    color: 'blue',
                    children: (
                        <>
                            <a
                                className='featured-presentation-link'
                                href={getPresentationUrl(presentationId, clientVersion)}
                                key={presentationId}
                            >
                                <GridItem
                                    image={
                                        <PresentationImage
                                            presentationId={presentationId}
                                            modifiedDate={modifiedDate}
                                        />
                                    }
                                    description={description}
                                    title={presentationName}
                                    subTitle={marketName}
                                    imagePosition={GridItemImagePosition.Bottom}
                                />
                            </a>
                        </>
                    ),
                };
            }
        );

        return (
            <div className={styles.list}>
                <Timeline style={{ marginTop: '8px' }} items={presentationsTemplate} />
            </div>
        );
    }

    return (
        <EmptyPresentationList>
            <p>A list of featured presentations will show here.</p>
        </EmptyPresentationList>
    );
}
