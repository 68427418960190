import { ReactElement, useEffect, useState } from 'react';
import { Empty, message, Row, Skeleton, Typography } from '@jll/react-ui-components';

import endpoints from 'utils/apiClient/endpoints';
import { UserAvatar } from '../UserAvatar';

import styles from './HereToSupportYou.module.scss';
interface Researcher {
    fullName: string;
    email: string;
    businessTitle: string;
    officeLocation: string;
    officeStateProvince: string;
    photo: string;
}

interface HereToSupportYouListProps {
    loading: boolean;
    researchers: Researcher[];
}

export default function HereToSupportYou({ ...others }): ReactElement {
    const [researchers, setResearcher] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getSupportResearchers = async () => {
            try {
                const response = await endpoints.researchers.all.get();
                const result = await response.json();
                setResearcher(result);
                setLoading(false);
            } catch (error) {
                message.error('Failed to load support researchers');
                console.error(error);
            }
        };
        getSupportResearchers();
    }, []);

    return (
        <div {...others} className={styles.container}>
            <Typography.Title className={styles.header} style={{ fontWeight: 400 }} level={4}>
                Here to Support You
            </Typography.Title>
            <ResearcherList loading={loading} researchers={researchers} />
        </div>
    );
}

function ResearcherList({ loading, researchers }: HereToSupportYouListProps): ReactElement {
    if (loading) {
        return (
            <div className={styles.list}>
                {[1, 2].map((item) => (
                    <Skeleton
                        active={true}
                        title={{ width: '100%' }}
                        paragraph={false}
                        key={item}
                        avatar={{ shape: 'circle' }}
                    />
                ))}
            </div>
        );
    }

    if (!researchers?.length) {
        return (
            <Empty
                className={styles.emptyResults}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description=''
            />
        );
    }

    return (
        <div className={styles.list}>
            {researchers.map(
                ({ businessTitle, email, officeLocation, fullName, photo }: Researcher) => {
                    return (
                        <a href={`mailto:${email}`} key={email}>
                            <div className={styles.listItem}>
                                <div
                                    style={{
                                        display: 'flex',
                                        margin: '0',
                                        alignItems: 'center',
                                    }}
                                >
                                    <UserAvatar fullName={fullName} photo={photo} />
                                </div>
                                <div>
                                    <Typography.Title style={{ marginBottom: 0 }} level={5}>
                                        {fullName}
                                    </Typography.Title>
                                    <Row
                                        wrap={false}
                                        style={{
                                            gap: '4px',
                                            whiteSpace: 'nowrap',
                                            display: 'grid',
                                            gridTemplateColumns: '1fr auto auto',
                                        }}
                                    >
                                        <Typography.Text ellipsis={{ tooltip: true }}>
                                            {businessTitle}
                                        </Typography.Text>
                                        {officeLocation?.trim() ? (
                                            <>
                                                <span> &bull; </span>
                                                <Typography.Text>{officeLocation}</Typography.Text>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </Row>
                                </div>
                            </div>
                        </a>
                    );
                }
            )}
        </div>
    );
}
