import { useState } from 'react';
import { Form, message, Modal, Radio, Select, Typography } from '@jll/react-ui-components';

import { downloadCsv, downloadKmz } from 'api/dataFilesApi';
import { MarketDetail } from 'api/marketApi';
import { fetchMarketDetails, selectMarketDetailedList } from 'store/marketListSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';

const { Paragraph, Title } = Typography;

const promptDownload = async (response: Response, fileName: string) => {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
};

interface DataFilesDialogProps {
    onClose: () => void;
    defaultMarketId?: number;
}

const DataFilesDialog = ({ onClose, defaultMarketId }: DataFilesDialogProps) => {
    const markets = useAppSelector(selectMarketDetailedList);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [downloading, setDownloading] = useState(false);
    const [selectedMarket, setSelectedMarket] = useState<MarketDetail | null>(null);

    if (markets === null) {
        dispatch(fetchMarketDetails());
    }

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                if (values.format === 'data') {
                    setDownloading(true);
                    downloadCsv(values.market)
                        .then(async (data) => {
                            const fileName = `${selectedMarket?.marketName} CSV Export.csv`;
                            await promptDownload(data, fileName);
                            setDownloading(false);
                        })
                        .catch((error) => {
                            message.error('Error downloading file, please try again later');
                            console.log(error);
                            setDownloading(false);
                        });
                } else {
                    setDownloading(true);
                    const popups = values.format.includes('popups');
                    downloadKmz(values.market, values.format !== '3d-models')
                        .then(async (data) => {
                            const fileName = `${selectedMarket?.marketName} Building Export${
                                popups ? ' and PopUps' : ''
                            }.kmz`;
                            await promptDownload(data, fileName);
                            setDownloading(false);
                        })
                        .catch((error) => {
                            message.error('Error downloading file, please try again later');
                            console.log(error);
                            setDownloading(false);
                        });
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            title='Data Files'
            open={true}
            destroyOnClose={true}
            okText='Download'
            onOk={handleSubmit}
            okButtonProps={{ loading: downloading }}
            cancelButtonProps={{ disabled: downloading }}
            onCancel={onClose}
            mask={false}
        >
            <Form form={form} initialValues={{ format: 'data', market: defaultMarketId }}>
                <Typography>
                    <Paragraph>
                        Access market property data and download 3-D building models to create
                        visualizations for your Blackbird presentations. You can export an excel
                        spreadsheet, building polygons, or building polygons with pop-up balloons to
                        use in the Blackbird web app, Google Earth, or the Data Authoring Tool.
                    </Paragraph>
                    <Paragraph>
                        Simply select a market and download which data file you would like to
                        export.
                    </Paragraph>
                    <Title level={5}>Select a market</Title>
                    <Form.Item
                        name='market'
                        rules={[
                            {
                                required: true,
                                message: 'Please select a market',
                            },
                        ]}
                    >
                        <Select
                            allowClear
                            showSearch
                            options={markets || []}
                            fieldNames={{ label: 'marketName', value: 'id' }}
                            onChange={(_val, option) =>
                                setSelectedMarket(Array.isArray(option) ? option[0] : option)
                            }
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Title level={5}>Select a format</Title>
                    <Form.Item name='format'>
                        <Radio.Group>
                            <Radio value='data' defaultChecked>
                                <Title level={5}>Data</Title>
                                <Paragraph>
                                    Excel.csv file with up to date property data for your selected
                                    market
                                </Paragraph>
                            </Radio>
                            <Radio value='3d-models'>
                                <Title level={5}>3D Models</Title>
                                <Paragraph>KML 3D models for building visualizations</Paragraph>
                            </Radio>
                            <Radio value='3d-models-with-popups'>
                                <Title level={5}>3D Models with Popups</Title>
                                <Paragraph>
                                    KML 3D models with property data popup balloons for building
                                    visualizations
                                </Paragraph>
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Typography>
            </Form>
        </Modal>
    );
};

export default DataFilesDialog;
