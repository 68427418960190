import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Spin } from '@jll/react-ui-components';
import formatDistance from 'date-fns/formatDistance';

import { PresentationSummary, searchPresentations } from 'api/presentationApi';
import SharedState from 'enums/SharedState';
import { getPresentationUrl } from 'utils/apiClient/urlUtil';
import { emailToDisplayName } from 'utils/emailUtils';
import WelcomeSectionHeader from '../welcome/WelcomeSectionHeader';
import EmptyPresentationList from './EmptyPresentationList';
import PresentationImage from './PresentationImage';

import styles from './PresentationList.module.scss';

export default function RecentlySharedPresentations(): ReactElement {
    const [presentations, setPresentations] = useState<PresentationSummary[] | undefined>();

    const title = 'Shared With Me';
    const linkTo = 'presentations/sharedWithMe';
    const linkTitle = 'Go to Shared';

    useEffect(() => {
        const getPresentations = async () => {
            try {
                const result = await searchPresentations({
                    orderBy: 'SharedDate desc',
                    sharedState: SharedState.SharedWithMe,
                    limit: 6,
                });

                setPresentations(result?.presentationSummaries);
            } catch (error) {
                console.error(error);
                // TODO: Show error message.
            }
        };

        getPresentations();
    }, []);

    return (
        <div style={{ padding: '32px' }}>
            <WelcomeSectionHeader
                title={title}
                titleSize={20}
                linkTo={linkTo}
                linkId='dashboard__go-to-shared-link'
                linkTitle={linkTitle}
            />
            {getPresentationsTemplates(presentations)}
        </div>
    );
}

function getPresentationsTemplates(presentations?: PresentationSummary[]) {
    if (!presentations) {
        return <Spin style={{ display: 'block', margin: '48px auto' }} />;
    }

    if (presentations.length) {
        const presentationsTemplate = presentations.map((presentation: PresentationSummary) => {
            let lastAccessedTemplate: ReactNode;
            if (presentation.accessedDate) {
                lastAccessedTemplate = `Viewed ${formatDistance(
                    presentation.accessedDate,
                    new Date()
                )}`;
            } else {
                lastAccessedTemplate = <span style={{ color: 'red' }}>Not opened yet</span>;
            }

            return (
                <a
                    href={getPresentationUrl(presentation.id, presentation.clientVersion)}
                    className={`recently-shared-presentation ${styles.listItem}`}
                    key={presentation.id}
                >
                    <div className={styles.listItemSection}>
                        <PresentationImage
                            presentationId={presentation.id}
                            modifiedDate={presentation.modifiedDate}
                        />
                    </div>
                    <div className={styles.listItemSection}>
                        <h3>{presentation.presentationName}</h3>
                        <p style={{ fontSize: '16px' }}>
                            shared by {emailToDisplayName(presentation.createdBy)}
                        </p>
                        <p>
                            {formatDistance(presentation.sharedDate, new Date())}
                            <span> &bull; </span>
                            {lastAccessedTemplate}
                        </p>
                    </div>
                </a>
            );
        });

        return <div>{presentationsTemplate}</div>;
    }

    return (
        <EmptyPresentationList>
            <p>A list of files recently shared with you will show here.</p>
        </EmptyPresentationList>
    );
}
