import { ReactElement, ReactNode } from 'react';
import { Dropdown, Row } from '@jll/react-ui-components';
import { MenuItemType } from 'rc-menu/lib/interface';

import { useAuth } from 'providers/AuthProvider';
import { selectIsClientUser } from 'store/userSlice';
import { useAppSelector } from 'types/hooks';
import { logLogout } from 'utils/analytics/blackbirdActivityLog';
import endpoints from 'utils/apiClient/endpoints';

import LogoutIcon from 'icons/custom/log-out.svg';

export default function UserMenu({ children }: { children: ReactNode }): ReactElement {
    const { signOut } = useAuth();
    const isClientUser = useAppSelector(selectIsClientUser);

    const items = [
        {
            label: (
                <Row id='logout-button' style={{ alignItems: 'center' }}>
                    <LogoutIcon style={{ fontSize: 20, marginRight: 8 }} />
                    Log out
                </Row>
            ),
            key: 'user_drawer_log_out',
        },
    ];

    async function handleMenuClick(menuItem: MenuItemType) {
        if (menuItem.key === 'user_drawer_log_out') {
            await logLogout();
            await endpoints.users.logout.post();
            await signOut({ clearTokensBeforeRedirect: true });
        }
    }

    return (
        <Dropdown
            menu={{
                onClick: handleMenuClick,
                items,
            }}
            trigger={['click']}
            disabled={isClientUser}
        >
            <div id='page-drawer-toggle' style={{ cursor: 'pointer' }}>
                {children}
            </div>
        </Dropdown>
    );
}
