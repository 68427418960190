import { ReactElement } from 'react';
import { Divider, Skeleton, Space, Typography } from '@jll/react-ui-components';

import {
    selectDataProviderTitle,
    selectPresentation,
    selectPresentationName,
    setPresentationName,
} from 'store/presentationSlice';
import { selectIsClientUser } from 'store/userSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import usePresentationActions from './usePresentationActions';

export default function PresentationName(): ReactElement {
    const dispatcher = useAppDispatch();
    const presentation = useAppSelector(selectPresentation);
    const presentationName = useAppSelector(selectPresentationName);
    const { savePresentation } = usePresentationActions();
    const isClientUser = useAppSelector(selectIsClientUser);
    const dataProviderTitle = useAppSelector(selectDataProviderTitle);

    const onPresentationNameUpdate = (newName: string) => {
        dispatcher(setPresentationName(newName));
        savePresentation(presentation.id, newName);
    };

    return (
        <>
            <Space.Compact
                direction='vertical'
                style={{ gap: 0, alignItems: 'start', marginLeft: 20 }}
            >
                <Space>
                    <Typography.Title
                        className='header-presentation-name'
                        editable={
                            !isClientUser && {
                                onChange: onPresentationNameUpdate,
                            }
                        }
                        level={5}
                        style={{ marginBottom: 0 }}
                    >
                        {presentationName || 'Untitled'}
                    </Typography.Title>
                </Space>
                <Space>
                    <Typography.Text>
                        {dataProviderTitle || (
                            <Skeleton.Input size='small' style={{ height: 16, marginTop: 4 }} />
                        )}
                    </Typography.Text>
                </Space>
            </Space.Compact>
            <Divider type='vertical' />
        </>
    );
}
