import { Icon, Row, Typography } from '@jll/react-ui-components';

import { HighlightSetTitleIcon } from 'components/HighlighSets/HighlightSetTitleIcon';
const { Text } = Typography;

interface HighlightSetMenuItemProps {
    title: string;
    color: string;
    checked?: boolean;
}

const HighlightSetMenuItem = ({ title, color, checked }: HighlightSetMenuItemProps) => {
    return (
        <Row wrap={false} align='middle' style={{ gap: '8px' }}>
            <HighlightSetTitleIcon highlightSetColor={color} />
            {checked ? (
                <Text strong>
                    {title} {<Icon name='check' />}
                </Text>
            ) : (
                <Text>{title}</Text>
            )}
        </Row>
    );
};
export default HighlightSetMenuItem;
