import { ReactElement, ReactNode } from 'react';
import type { SelectProps } from '@jll/react-ui-components';
import { Select } from '@jll/react-ui-components';

import { fetchAllMarketSummaries, selectMarketList } from 'store/marketListSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';

export interface MarketSummaryOption {
    key?: string;
    value: number;
    label: ReactNode;
}

type MarketSelectProps = Omit<
    SelectProps<MarketSummaryOption | MarketSummaryOption[]>,
    'options' | 'children'
>;

export default function MarketSelect({ ...props }: MarketSelectProps): ReactElement {
    const dispatch = useAppDispatch();
    const markets = useAppSelector(selectMarketList);

    if (markets === null) {
        dispatch(fetchAllMarketSummaries());
    }

    const marketSummaryOptions = markets?.map((item): MarketSummaryOption => {
        return { value: item.id, label: item.marketName };
    }, markets);

    return (
        <Select
            labelInValue
            filterOption={(input, option) =>
                (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
            options={marketSummaryOptions}
            {...props}
        />
    );
}
