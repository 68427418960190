import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
    Form,
    FormInstance,
    Input,
    message,
    Radio,
    Select,
    Skeleton,
} from '@jll/react-ui-components';

import { MappedMarket, MarketDetail, updateMarketDetails } from 'api/marketApi';
import { marketComponents } from 'constants/markets.constants';
import { GET_MARKETS } from 'services/graphql/csp';
import {
    fetchMarketDetails,
    fetchMarketSphereMarkets,
    selectMarketSphereMarkets,
} from 'store/marketListSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import { INQUIRY_CLIENT } from 'utils/gqlUtils';
import CspMarketEditorFormItems from './CspMarketEditorFormItems';
import MarketSphereMarketEditorFormItems from './MarketSphereMarketEditorFormItems';

interface MarketDetailEntryFormProps {
    form: FormInstance;
    action: string;
    market: MarketDetail | Record<string, never>;
    onSaveSuccess: () => void;
    setSaving: (saving: boolean) => void;
}

const MarketDetailEntryForm = ({
    form,
    action: _action,
    market,
    onSaveSuccess,
    setSaving,
}: MarketDetailEntryFormProps) => {
    const dispatch = useAppDispatch();

    const cspQuery = useQuery(GET_MARKETS, {
        context: { clientName: INQUIRY_CLIENT },
    });

    const marketSphereMarkets = useAppSelector(selectMarketSphereMarkets);

    useEffect(() => {
        if (marketSphereMarkets === null) dispatch(fetchMarketSphereMarkets());
    }, [dispatch, marketSphereMarkets]);

    const marketSphereMarketsOptions = useMemo(
        () =>
            (marketSphereMarkets || []).reduce(
                (acc: Record<string, Record<string, string | MappedMarket>>, m: MappedMarket) => {
                    const key = `${m['code']}-${m['propertyTypeCode']}`;
                    acc[key] = {
                        key,
                        value: `${m['code']}-${m['propertyTypeCode']}`,
                        label: m['label'],
                        market: m,
                    };
                    return acc;
                },
                {} as Record<string, Record<string, string>>
            ),
        [marketSphereMarkets]
    );

    if (marketSphereMarkets === null || cspQuery.loading) {
        return <Skeleton active />;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    const transformValues = (values: any) => {
        const {
            marketSphereComponents,
            marketSphereMarkets,
            cspMarkets,
            propertyProviderId,
            ...others
        } = values;
        if (propertyProviderId === 4) {
            const mappedMarkets = marketSphereMarkets.map((m: string) => {
                return marketSphereMarketsOptions[m]['market'];
            });
            const mappedComponents = marketSphereComponents.map((m: string | number) => {
                return marketComponents.find(
                    (mc: Record<string, string | boolean | number>) => mc.id == m
                );
            });
            return { ...market, ...others, propertyProviderId, mappedMarkets, mappedComponents };
        } else if (propertyProviderId === 5) {
            return {
                ...market,
                ...others,
                propertyProviderId,
                mappedMarkets: cspMarkets.map((m: string) => ({
                    name: m,
                    label: m,
                    code: 0,
                    propertyTypeName: null,
                    propertyTypeCode: 0,
                })),
            };
        } else {
            return { ...market, ...others, propertyProviderId };
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    const handleSubmit = (values: any) => {
        setSaving(true);
        updateMarketDetails(transformValues(values))
            .then(async () => {
                message.success('Successfully Saved');
                onSaveSuccess();
                dispatch(fetchMarketDetails());
            })
            .catch((error) => {
                message.error('Error saving, please try again later');
                console.error(error);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const initialValues =
        !!market && Object.keys(market).length > 0
            ? {
                  ...market,
                  marketSphereMarkets: market.mappedMarkets.map(
                      (m) => `${m['code']}-${m['propertyTypeCode']}`
                  ),
                  cspMarkets: market.mappedMarkets.map((m) => m.label),
                  marketSphereComponents: market.mappedComponents.map((m) => m['id']),
              }
            : {};

    return (
        <Form form={form} layout='vertical' onFinish={handleSubmit} initialValues={initialValues}>
            <Form.Item
                label='Market Name'
                name='marketName'
                rules={[
                    {
                        required: true,
                        message: 'Please enter a market name',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label='Default Country'
                name='countryId'
                rules={[
                    {
                        required: true,
                        message: 'Please select a country',
                    },
                ]}
            >
                <Select>
                    <Select.Option value='US'>United States</Select.Option>
                    <Select.Option value='CA'>Canada</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label='Market Type'
                name='marketTypeId'
                rules={[
                    {
                        required: true,
                        message: 'Please select a market type',
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value={1}>Industrial</Radio>
                    <Radio value={2}>Office</Radio>
                    <Radio value={3}>Retail</Radio>
                    <Radio value={9}>HealthCare</Radio>
                    <Radio value={10}>Multi-Housing</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label='Property Data Provider'
                name='propertyProviderId'
                rules={[
                    {
                        required: true,
                        message: 'Please select a property data provider',
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value={4}>MarketSphere</Radio>
                    <Radio value={5}>Core Services Platform</Radio>
                    <Radio value={0}>None</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.propertyProviderId !== currentValues.propertyProviderId ||
                    prevValues.defaultPresentationId != currentValues.defaultPresentationId ||
                    prevValues.marketSphereMarkets != currentValues.marketSphereMarkets
                }
            >
                {({ getFieldValue }) => {
                    const propertyProviderId = getFieldValue('propertyProviderId');
                    const defaultPresentationId = getFieldValue('defaultPresentationId');
                    const marketSphereMarkets = getFieldValue('marketSphereMarkets');

                    switch (propertyProviderId) {
                        case 4:
                            return (
                                <MarketSphereMarketEditorFormItems
                                    marketSphereMarkets={marketSphereMarkets}
                                    marketSphereMarketsOptions={marketSphereMarketsOptions}
                                    defaultPresentationId={defaultPresentationId}
                                />
                            );

                        case 5:
                            return (
                                <CspMarketEditorFormItems cspMarketData={cspQuery.data?.markets} />
                            );

                        default:
                            break;
                    }

                    return null;
                }}
            </Form.Item>
        </Form>
    );
};

export default MarketDetailEntryForm;
