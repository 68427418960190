import { ReactElement } from 'react';
import { CameraOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row, Space } from '@jll/react-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import EnablePresentationModeButton from 'components/presentationMode/EnablePresentationModeButton';
import SavePresentationButton from 'components/presentations/SavePresentationButton';
import SharePresentationButton from 'components/presentationShare/SharePresentationButton';
import useSlideCapture from 'components/Slides/hooks/useSlideCapture';
import { UserAvatar } from 'components/UserAvatar';
import UserMenu from 'components/UserMenu';
import { selectIsSinglePropertyViewVisible } from 'store/leftPanelSlice';
import { selectPresentation } from 'store/presentationSlice';
import { setSelectedPropertyId, setSinglePropertyView } from 'store/singlePropertyViewSlice';
import { selectCurrentUser, selectIsClientUser } from 'store/userSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import PresentationName from '../presentations/PresentationName';

import styles from './PageHeader.module.scss';

import BlackbirdLogo from 'images/blackbird-logo.svg';

const { Header } = Layout;

export default function PageHeader({
    isPresentation = false,
}: {
    isPresentation?: boolean;
}): ReactElement {
    const user = useAppSelector(selectCurrentUser);

    return (
        <Header
            style={{
                backgroundColor: 'white',
            }}
            className={styles.header}
        >
            <Row className={styles.sections}>
                <Col span={12} className={styles.left}>
                    <Space>
                        <BlackbirdLogo
                            id='blackbird-logo'
                            className={styles.logo}
                            onClick={() => {
                                window.location.href = '/';
                            }}
                        />
                        {isPresentation && <PresentationName />}
                    </Space>
                </Col>
                <Col span={12} className={styles.right}>
                    {isPresentation && <PresentationHeaderControls />}
                    <UserMenu>
                        <UserAvatar fullName={user.displayName} />
                    </UserMenu>
                </Col>
            </Row>
        </Header>
    );
}

function PresentationHeaderControls() {
    const dispatch = useAppDispatch();
    const isClientUser = useAppSelector(selectIsClientUser);
    const slideAction = useSlideCapture();
    const presentation = useAppSelector(selectPresentation);
    const isSinglePropertyView = useAppSelector(selectIsSinglePropertyViewVisible);
    const { arcgisPresentationMode: showPresentationMode } = useFlags();
    return (
        <>
            {!isClientUser && (
                <>
                    <SharePresentationButton presentation={presentation} />
                    <SavePresentationButton presentation={presentation} />
                    <Button
                        type='primary'
                        icon={<CameraOutlined />}
                        onClick={() => {
                            slideAction.captureSlide();
                            if (isSinglePropertyView) {
                                dispatch(setSinglePropertyView(false));
                                dispatch(setSelectedPropertyId(''));
                            }
                        }}
                    >
                        Capture Slide
                    </Button>
                </>
            )}

            {showPresentationMode && <EnablePresentationModeButton />}
        </>
    );
}
