import { useMemo, useState } from 'react';
import { AdvancedImage, responsive } from '@cloudinary/react';

import { getImage } from 'utils/cloudinaryUtils';

import NewPresentationIcon from 'icons/custom/newPresentation.svg';

interface PresentationImage {
    presentationId: number;
    modifiedDate: Date;
}
export default function PresentationImage(props: PresentationImage): JSX.Element {
    const [failed, setFailed] = useState(false);

    const image = useMemo(() => {
        setFailed(false);
        return getImage({
            folder: 'presentation',
            id: props.presentationId,
            version: new Date(props.modifiedDate).getTime(),
        });
    }, [props.presentationId, props.modifiedDate]);

    // If image doesn't exist, display NewPresentationIcon
    if (failed) {
        return (
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    background: '#ddd',
                }}
            >
                <NewPresentationIcon width='50%' height='50%' color='#aaa' />
            </div>
        );
    } else {
        return (
            <AdvancedImage
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    opacity: failed ? 0 : 1,
                }}
                onError={() => setFailed(true)}
                cldImg={image}
                plugins={[responsive({ steps: [64, 256, 512, 1024] })]}
            />
        );
    }
}
