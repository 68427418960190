import { LockOutlined } from '@ant-design/icons';
import { Button } from '@jll/react-ui-components';

import { canEditPresentation, Presentation } from 'api/presentationApi';
import {
    closePresentationShareModal,
    openPresentationShareModal,
} from 'store/presentationShareSlice';
import { useAppDispatch } from 'types/hooks';
import { SharePresentationModal } from './SharePresentationModal';

import buttonStyles from './SharePresentationButton.module.scss';

export default function SharePresentationButton({
    presentation,
    className = buttonStyles.shareButton,
    type = 'primary',
}: {
    presentation: Presentation;
    className?: string;
    type?: 'primary' | 'link' | 'text' | 'default' | 'dashed' | undefined;
}) {
    const dispatch = useAppDispatch();
    return (
        <>
            <Button
                className={className}
                type={type}
                icon={<LockOutlined />}
                disabled={presentation?.id === 0 || !canEditPresentation(presentation)}
                onClick={() => dispatch(openPresentationShareModal())}
            >
                Share
            </Button>

            <SharePresentationModal
                presentationName={presentation.presentationName}
                presentationId={presentation.id}
                onCancel={() => dispatch(closePresentationShareModal())}
                isPublished={presentation.isPublished}
            />
        </>
    );
}
