import { Button, Col, Row, Typography } from '@jll/react-ui-components';

import { ShareInfoDtoShareEntry } from 'api/presentationApi';
import { openPresentationInternalInviteModal } from 'store/presentationShareSlice';
import { useAppDispatch } from 'types/hooks';
import { InternalShareInviteModal } from './InternalShareInviteModal';
import InternalShareList from './InternalShareList';

export default function InternalShareSection({
    presentationId,
    presentationName,
    sharedUsers,
}: {
    presentationId: number;
    presentationName: string;
    sharedUsers: ShareInfoDtoShareEntry[];
}) {
    const dispatch = useAppDispatch();
    return (
        <>
            <Row justify='space-between'>
                <Col>
                    <Typography.Text
                        style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                        }}
                    >
                        Share with people and groups
                    </Typography.Text>
                    <br />
                    <Typography.Text style={{ fontSize: '12px' }}>
                        Collaborate privately with others
                    </Typography.Text>
                </Col>
                <Col>
                    <Button onClick={() => dispatch(openPresentationInternalInviteModal())}>
                        Invite
                    </Button>
                </Col>
            </Row>
            <InternalShareList
                sharedUsers={sharedUsers}
                presentationId={presentationId}
                presentationName={presentationName}
            />
            <InternalShareInviteModal
                sharedUsers={sharedUsers}
                presentationId={presentationId}
                presentationName={presentationName}
            />
        </>
    );
}
