// create a functional component
import { useEffect, useState } from 'react';
import { Form, FormInstance, Input, message, Skeleton } from '@jll/react-ui-components';

import {
    getMarketUserRole,
    MarketDetail,
    updateMarketUserRole,
    UserMarketRole,
} from 'api/marketApi';

const { TextArea } = Input;

interface MarketAccessFormProps {
    form: FormInstance;
    market: MarketDetail;
    onSaveSuccess: () => void;
    setSaving: (saving: boolean) => void;
}

interface InitialValuesProps {
    marketUserEmails: string;
    polyUserEmails: string;
}

const MarketAccessForm = ({ form, market, onSaveSuccess, setSaving }: MarketAccessFormProps) => {
    const [initialValues, setInitialValues] = useState<InitialValuesProps | undefined>(undefined);

    useEffect(() => {
        if (market) {
            getMarketUserRole(market.id).then((activeMarketRoles) => {
                setInitialValues({
                    marketUserEmails: activeMarketRoles.marketUserEmails?.join('\n') || '',
                    polyUserEmails: activeMarketRoles.polyUserEmails?.join('\n') || '',
                });
            });
        }
    }, [market]);

    if (!initialValues) {
        return <Skeleton active />;
    }

    const handleSubmit = (values: Record<string, string>) => {
        const updatedMarketRole: UserMarketRole = {
            marketUserEmails: values['marketUserEmails'].split('\n'),
            polyUserEmails: values['polyUserEmails'].split('\n'),
        };
        setSaving(true);
        updateMarketUserRole(market.id, updatedMarketRole)
            .then(async () => {
                message.success('Successfully Saved');
                onSaveSuccess();
            })
            .catch((error) => {
                message.error('Error saving, please try again later');
                console.error(error);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    return (
        <Form form={form} layout='vertical' onFinish={handleSubmit} initialValues={initialValues}>
            <Form.Item label='People who can maintain the ENTIRE MARKET' name='marketUserEmails'>
                <TextArea autoSize={{ minRows: 4, maxRows: 10 }} />
            </Form.Item>
            <Form.Item label='People who can draw and edit POLYGONS:' name='polyUserEmails'>
                <TextArea autoSize={{ minRows: 4, maxRows: 10 }} />
            </Form.Item>
        </Form>
    );
};

export default MarketAccessForm;
