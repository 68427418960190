import { LinkOutlined } from '@ant-design/icons';
import { Button, Col, Input, message, Row, Typography } from '@jll/react-ui-components';

export default function InternalLinkSection({ presentationId }: { presentationId: number }) {
    const copyLinkUrl = `${window.location.origin}/presentation/${presentationId}`;
    const copyLink = () => {
        window.navigator.clipboard.writeText(copyLinkUrl).then(() => {
            message.success('Copied to Clipboard');
        });
    };

    return (
        <>
            <Row>
                <Typography.Text
                    style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        marginBottom: 5,
                    }}
                >
                    Get internal link
                </Typography.Text>
            </Row>
            <Row>
                <Col
                    style={{
                        width: '430px',
                    }}
                >
                    <Input readOnly value={copyLinkUrl} />
                </Col>
                <Col>
                    <Button type='text' onClick={copyLink}>
                        <LinkOutlined style={{ fontSize: '16px', color: '#e30813' }} />
                        <Typography.Text style={{ fontSize: '16px', color: '#e30813' }}>
                            Copy link
                        </Typography.Text>
                    </Button>
                </Col>
            </Row>
        </>
    );
}
