import { ReactElement } from 'react';

import TypographyLink from 'components/TypographyLink';

import styles from './WelcomeSectionHeader.module.scss';

interface WelcomeSectionHeaderProps {
    title: string;
    titleSize: number;
    linkTo?: string;
    linkId?: string;
    linkTitle?: string;
}

export default function WelcomeSectionHeader(props: WelcomeSectionHeaderProps): ReactElement {
    const { title, titleSize, linkTo, linkId, linkTitle } = props;
    return (
        <header className={styles.header}>
            <h3 style={{ fontSize: titleSize }}>{title}</h3>
            {linkTo != undefined && (
                <TypographyLink id={linkId} to={linkTo}>
                    {linkTitle}
                </TypographyLink>
            )}
        </header>
    );
}
