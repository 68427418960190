import { useState } from 'react';
import { EditOutlined, LockOutlined } from '@ant-design/icons';
import { Button } from '@jll/react-ui-components';

import { MarketDetail } from 'api/marketApi';
import MarketDetailsEntryDialog from 'components/marketEntry/MarketDetailsEntryDialog';

interface MarketListActionsProps {
    record: MarketDetail;
}
const MarketListActions = ({ record }: MarketListActionsProps) => {
    const [showDialog, setShowDialog] = useState(false);
    const [lastAction, setLastAction] = useState('');

    const handleEdit = () => {
        setLastAction('edit');
        setShowDialog(true);
    };

    const handleAccess = () => {
        setLastAction('access');
        setShowDialog(true);
    };

    const closeDialog = () => {
        setShowDialog(false);
    };

    return (
        <div>
            {showDialog && (
                <MarketDetailsEntryDialog
                    market={record}
                    lastAction={lastAction}
                    closeDialog={closeDialog}
                />
            )}
            <Button type='link' icon={<LockOutlined />} onClick={handleAccess} />
            <Button type='link' icon={<EditOutlined />} onClick={handleEdit} />
        </div>
    );
};

export default MarketListActions;
