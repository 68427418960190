import { useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Divider, Modal, Typography } from '@jll/react-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
    fetchPresentationShareStatistics,
    selectPresentationExternalInviteModalOpen,
    selectPresentationInternalInviteModalOpen,
    selectPresentationShareModalOpen,
    selectPresentationShareStatistics,
} from 'store/presentationShareSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import ExportSlidesSection from './ExportSlidesSection';
import ExternalShareSection from './ExternalShareSection';
import InternalLinkSection from './InternalLinkSection';
import InternalShareSection from './InternalShareSection';
import PublishSection from './PublishSection';

const CustomDivider = ({ marginTop }: { marginTop?: number }) => (
    <Divider
        style={{
            marginTop: marginTop || 20,
            marginLeft: '-24px',
            width: '600px',
            border: '1px solid #e1e1e3',
            marginBottom: '10px',
        }}
    />
);

export function SharePresentationModal({
    presentationName,
    presentationId,
    onCancel,
    isPublished,
}: {
    presentationName: string;
    presentationId: number;
    onCancel: () => void;
    isPublished: boolean;
}) {
    const dispatch = useAppDispatch();
    const presentationShareModalOpen = useAppSelector(selectPresentationShareModalOpen);
    const presentationShareStatistics = useAppSelector(selectPresentationShareStatistics);
    const internalInviteModalOpen = useAppSelector(selectPresentationInternalInviteModalOpen);
    const externalInviteModalOpen = useAppSelector(selectPresentationExternalInviteModalOpen);

    const { arcgisClientShare, exportSlides } = useFlags();

    useEffect(() => {
        if (presentationId && presentationShareModalOpen) {
            dispatch(fetchPresentationShareStatistics(presentationId));
        }
    }, [presentationId, presentationShareModalOpen, dispatch]);

    return (
        <Modal
            title={
                <Typography.Text
                    style={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                    }}
                >{`Share ${presentationName}`}</Typography.Text>
            }
            open={
                presentationShareModalOpen && !internalInviteModalOpen && !externalInviteModalOpen
            }
            onCancel={onCancel}
            closeIcon={<CloseOutlined />}
            width='600px'
            centered
            footer={null}
        >
            <CustomDivider marginTop={5} />
            <PublishSection published={isPublished} presentationId={presentationId} />
            <CustomDivider />
            <InternalShareSection
                presentationId={presentationId}
                presentationName={presentationName}
                sharedUsers={presentationShareStatistics.filter((s) => !s.user.isClientUser)}
            />
            <CustomDivider />

            {arcgisClientShare && (
                <>
                    <ExternalShareSection
                        presentationId={presentationId}
                        clientUsers={presentationShareStatistics.filter((s) => s.user.isClientUser)}
                    />
                    <CustomDivider />
                </>
            )}
            <InternalLinkSection presentationId={presentationId} />
            <CustomDivider />
            {exportSlides && <ExportSlidesSection onExport={onCancel} />}
        </Modal>
    );
}
