import { useMemo } from 'react';
import { Form, Select } from '@jll/react-ui-components';
import { uniq } from 'lodash';

const CspMarketEditorFormItems = ({ cspMarketData }: { cspMarketData: string[] }) => {
    const cspMarkets = useMemo(
        () =>
            uniq(cspMarketData)
                .sort()
                .map((m: unknown) => ({
                    key: m,
                    value: m,
                    label: m,
                })),
        [cspMarketData]
    );

    return (
        <Form.Item
            label='CSP Markets'
            name='cspMarkets'
            rules={[
                {
                    required: true,
                    message: 'Please select a CSP market',
                },
            ]}
        >
            <Select mode='multiple' allowClear options={cspMarkets} />
        </Form.Item>
    );
};

export default CspMarketEditorFormItems;
