import { EyeOutlined } from '@ant-design/icons';
import {
    Avatar,
    Button,
    Col,
    ConfigProvider,
    List,
    message,
    Popconfirm,
    Row,
    Space,
    Typography,
} from '@jll/react-ui-components';
import { formatDistance } from 'date-fns';
import { capitalize } from 'lodash';

import { renewExternalClientShare, revokeExternalClientShare } from 'api/clientShareApi';
import { ShareInfoDtoShareEntry } from 'api/presentationApi';
import {
    fetchPresentationShareStatistics,
    openPresentationExternalInviteModal,
} from 'store/presentationShareSlice';
import { useAppDispatch } from 'types/hooks';
import { avatarColor, buildUserInitialsFromEmail } from 'utils/userUtils';
import { ExternalShareInviteModal } from './ExternalShareInviteModal';

const lastAccessText = (lastAccessTime: string) => {
    if (lastAccessTime) {
        return capitalize(
            formatDistance(new Date(lastAccessTime), new Date(), {
                addSuffix: true,
            })
        );
    }
    return 'Invite Pending';
};
export default function ExternalShareSection({
    presentationId,
    clientUsers,
}: {
    presentationId: number;
    clientUsers: ShareInfoDtoShareEntry[];
}) {
    const dispatch = useAppDispatch();

    const handleRevoke = async (email: string) => {
        await revokeExternalClientShare(presentationId, email);
        dispatch(fetchPresentationShareStatistics(presentationId));
    };

    const handleResend = async (email: string) => {
        await renewExternalClientShare(presentationId, email);
        message.success('Resent email');
    };

    return (
        <>
            <Row justify='space-between'>
                <Col>
                    <Typography.Text
                        style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                        }}
                    >
                        Share with clients
                    </Typography.Text>
                </Col>
                <Col>
                    <Button onClick={() => dispatch(openPresentationExternalInviteModal())}>
                        Invite
                    </Button>
                </Col>
            </Row>
            <ConfigProvider
                renderEmpty={() => <span>Not shared with any clients yet. Be the first one.</span>}
            >
                <List
                    dataSource={clientUsers}
                    style={{ maxHeight: 200, overflowY: 'auto', paddingRight: 20, marginTop: 10 }}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Popconfirm
                                    key='revoke'
                                    title='Revoke Access'
                                    description='Are you sure you want to revoke access?'
                                    onConfirm={() => handleRevoke(item.user.email)}
                                    okText='Yes'
                                    cancelText='No'
                                >
                                    <Button size='small' type='link'>
                                        Revoke
                                    </Button>
                                </Popconfirm>,
                                <Popconfirm
                                    key='resend'
                                    title='Resend Email'
                                    description='Are you sure you want to resent email?'
                                    onConfirm={() => handleResend(item.user.email)}
                                    okText='Yes'
                                    cancelText='No'
                                >
                                    <Button size='small' type='link' key='resend'>
                                        Resend
                                    </Button>
                                </Popconfirm>,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={
                                    <Avatar
                                        style={{ backgroundColor: avatarColor(item.user.email) }}
                                    >
                                        {buildUserInitialsFromEmail(item.user.email)}
                                    </Avatar>
                                }
                                title={item.user.email}
                            />
                            <Space>
                                <span>
                                    <EyeOutlined />
                                    <Typography.Text italic style={{ paddingLeft: 2 }}>
                                        {item.statistics?.accessCount || 0}
                                    </Typography.Text>
                                </span>
                                <Typography.Text italic style={{ fontSize: 12 }}>
                                    {lastAccessText(item.statistics?.lastAccessTime || '')}
                                </Typography.Text>
                            </Space>
                        </List.Item>
                    )}
                />
            </ConfigProvider>
            <ExternalShareInviteModal presentationId={presentationId} />
        </>
    );
}
