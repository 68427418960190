import endpoints from 'utils/apiClient/endpoints';

export interface MarketQueryResultResponse {
    total: number;
    marketSummaries: MarketSummary[];
}

export interface MarketSearchOptions {
    searchQuery?: string;
    offset?: number;
    limit?: number;
}

export interface MarketSummary {
    id: number;
    marketName: string;
    propertyProviderId?: number;
}

export interface MappedComponent {
    id: number;
    name: string;
    isActive: boolean;
}

export interface MappedMarket {
    code: number;
    label: string;
    name: string;
    propertyTypeCode: number;
    propertyTypeName: string;
}

export interface UserMarketRole {
    marketUserEmails: string[];
    polyUserEmails: string[];
}

export interface MarketDetail {
    id: number;
    marketName: string;
    countryId: string;
    createdBy: string;
    createdDate: string;
    modifiedBy: string;
    modifiedDate: string;
    marketTypeId: number;
    altitude: number;
    altitudeMode: string;
    east: number;
    heading: number;
    latitude: number;
    longitude: number;
    marketDescription?: string;
    north: number;
    range: number;
    south: number;
    tilt: number;
    west: number;
    propertyProviderId: number;
    stateId: number;
    mappedMarkets: MappedMarket[];
    mappedComponents: MappedComponent[];
    sourceSystems: string[];
    defaultPresentationId: number;
}

export async function searchMarkets(
    options: MarketSearchOptions
): Promise<MarketQueryResultResponse> {
    const response = await endpoints.markets.search.get({
        queryParameters: options as Record<string, string | number | boolean>,
    });

    return (await response.json()) as MarketQueryResultResponse;
}

export async function getMarkets(): Promise<MarketSummary[]> {
    const response = await endpoints.markets.all.get();
    return (await response.json()) as MarketSummary[];
}

export async function getMarketUserRole(marketId: number): Promise<UserMarketRole> {
    const response = await endpoints.markets.userMarketRoles.get({
        templateValues: { marketId } as Record<string, number>,
    });
    return (await response.json()) as UserMarketRole;
}

export async function updateMarketUserRole(
    marketId: number,
    userMarketRole: UserMarketRole
): Promise<Response> {
    return await endpoints.markets.userMarketRoles.post({
        templateValues: { marketId } as Record<string, number>,
        fetchOptions: {
            body: JSON.stringify(userMarketRole),
        },
    });
}

export async function updateMarketDetails(market: MarketDetail): Promise<Response> {
    return await endpoints.markets.save.post({
        fetchOptions: {
            body: JSON.stringify(market),
        },
    });
}

export const getPropertyMappings = async (marketId: number) => {
    const response = await endpoints.propertyGeometries.getBlackbirdPropertyMappings.get({
        templateValues: { marketId },
    });

    return await response.json();
};
