import { MouseEventHandler, ReactNode } from 'react';
import { Typography } from '@jll/react-ui-components';

import styles from './GridItem.module.scss';

const { Text, Title } = Typography;

export enum GridItemImagePosition {
    Top,
    Bottom,
}
interface GridItemProps {
    id?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
    selected?: boolean;
    title: string | ReactNode;
    subTitle: string | ReactNode;
    description?: string | ReactNode;
    image?: ReactNode;
    imageUrl?: string;
    imagePosition?: GridItemImagePosition;
}

export default function GridItem({
    id,
    onClick,
    subTitle,
    title,
    description,
    image,
    imageUrl,
    selected,
    imagePosition = GridItemImagePosition.Top,
}: GridItemProps) {
    const imageElement = (image && <>{image}</>) || (imageUrl && <img src={imageUrl} />);

    const descriptionNode = description ? (
        <Text className={styles.description}>{description}</Text>
    ) : null;
    return (
        <div
            id={id}
            onClick={onClick}
            className={[styles.gridItem, selected ? styles.selected : ''].filter(Boolean).join(' ')}
        >
            {imagePosition === GridItemImagePosition.Top && (
                <div style={{ marginBottom: '8px' }} className={styles.imageContainer}>
                    {imageElement}
                </div>
            )}
            <Text className={styles.subTitle}>{subTitle}</Text>
            <Title level={5} style={{ margin: 0 }} className={styles.title}>
                {title}
            </Title>
            {descriptionNode}
            {imagePosition === GridItemImagePosition.Bottom && (
                <div style={{ marginTop: '8px' }} className={styles.imageContainer}>
                    {imageElement}
                </div>
            )}
        </div>
    );
}
