import { useState } from 'react';
import { DownOutlined, GroupOutlined } from '@ant-design/icons';
import {
    Avatar,
    ConfigProvider,
    Dropdown,
    List,
    MenuProps,
    Space,
    Typography,
} from '@jll/react-ui-components';

import { GroupPermission, ShareInfoDtoShareEntry, shareWithUsers } from 'api/presentationApi';
import {
    fetchPresentationShareStatistics,
    selectPresentationShareStatistics,
} from 'store/presentationShareSlice';
import { selectPresentationGroups } from 'store/presentationSlice';
import { selectCurrentUser } from 'store/userSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import { avatarColor, buildUserInitialsFromEmail, permissionTypeName } from 'utils/userUtils';

const actions: MenuProps['items'] = [
    {
        label: 'View',
        key: 1,
    },
    {
        label: 'Edit',
        key: 2,
    },
    {
        type: 'divider',
    },
    {
        label: 'Revoke Access',
        key: 0,
    },
];

function InternalShareUserItem({
    item,
    onAccessChange,
}: {
    item: ShareInfoDtoShareEntry;
    onAccessChange: (user: ShareInfoDtoShareEntry, accessType: number) => void;
}) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    const handleMenuClick = (e: any) => {
        onAccessChange(item, e.key);
    };

    return (
        <List.Item>
            <List.Item.Meta
                avatar={
                    <Avatar
                        style={{
                            backgroundColor: avatarColor(item.user.email),
                        }}
                    >
                        {buildUserInitialsFromEmail(item.user.email)}
                    </Avatar>
                }
                title={item.user.email}
            />
            <Dropdown
                menu={{ items: actions, onClick: (e) => handleMenuClick(e) }}
                disabled={item.user.accessType === 3}
                trigger={['click']}
            >
                <Space size='small'>
                    <Typography.Text italic>
                        {permissionTypeName(item.user.accessType)}
                    </Typography.Text>
                    {item.user.accessType !== 3 && <DownOutlined style={{ paddingTop: 7 }} />}
                </Space>
            </Dropdown>
        </List.Item>
    );
}
function InternalShareGroupItem({
    item,
    onAccessChange,
}: {
    item: GroupPermission;
    onAccessChange: (group: GroupPermission, accessType: number) => void;
}) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    const handleMenuClick = (e: any) => {
        onAccessChange(item, e.key);
    };

    return (
        <List.Item>
            <List.Item.Meta
                avatar={
                    <Avatar
                        style={{
                            backgroundColor: '#d8d9d9',
                        }}
                    >
                        <GroupOutlined />
                    </Avatar>
                }
                title={item.groupName}
            />
            <Dropdown
                menu={{ items: actions, onClick: (e) => handleMenuClick(e) }}
                trigger={['click']}
            >
                <Space size='small'>
                    <Typography.Text italic>{permissionTypeName(item.accessType)}</Typography.Text>
                    <DownOutlined style={{ paddingTop: 7 }} />
                </Space>
            </Dropdown>
        </List.Item>
    );
}
export default function InternalShareList({
    presentationId,
    presentationName,
    sharedUsers,
}: {
    presentationId: number;
    presentationName: string;
    sharedUsers: ShareInfoDtoShareEntry[];
}) {
    const presentationGroups = useAppSelector(selectPresentationGroups) || [];
    const presentationShareStatistics = useAppSelector(selectPresentationShareStatistics);
    const currentUser = useAppSelector(selectCurrentUser);
    const owners = sharedUsers.filter((u) => u.user.accessType === 3);
    const restSharedUsers = sharedUsers.filter((u) => u.user.accessType !== 3);
    const sortedSharedUsers = [...owners, ...restSharedUsers];
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const handleUserAccessChange = (user: ShareInfoDtoShareEntry, accessType: number) => {
        const level1Access = presentationShareStatistics
            .filter((u) => u.user.accessType === 1 && u.user.email !== user.user.email)
            .map((u) => u.user.email);

        const level2Access = presentationShareStatistics
            .filter((u) => u.user.accessType === 2 && u.user.email !== user.user.email)
            .map((u) => u.user.email);

        if (accessType === 1) {
            level1Access.push(user.user.email);
        }

        if (accessType === 2) {
            level2Access.push(user.user.email);
        }

        setLoading(true);
        shareWithUsers(presentationId, {
            id: presentationId,
            level1Access: level1Access.join(';'),
            level2Access: level2Access.join(';'),
            presentationName,
            updatedBy: currentUser.userPrincipalName,
        }).then(() => {
            dispatch(fetchPresentationShareStatistics(presentationId));
            setLoading(false);
        });
    };

    const handleGroupAccessChange = (_group: GroupPermission, _accessType: number) => {
        //TODO: This needs to be done. Currently we do not have a way to add a group
        // to a presentation. This will be complete in next iteration
    };

    return (
        <ConfigProvider
            renderEmpty={() => (
                <span>Not shared with any user yet. Be the first one to share.</span>
            )}
        >
            <List
                loading={presentationShareStatistics.length === 0 || loading}
                dataSource={[...sortedSharedUsers, ...presentationGroups]}
                style={{ maxHeight: 200, overflowY: 'auto', paddingRight: 20, marginTop: 10 }}
                renderItem={(item) =>
                    Object.hasOwn(item, 'groupName') ? (
                        <InternalShareGroupItem
                            item={item as GroupPermission}
                            onAccessChange={(group, accessType) =>
                                handleGroupAccessChange(group, accessType)
                            }
                        />
                    ) : (
                        <InternalShareUserItem
                            item={item as ShareInfoDtoShareEntry}
                            onAccessChange={(user, accessType) =>
                                handleUserAccessChange(user, Number(accessType))
                            }
                        />
                    )
                }
            />
        </ConfigProvider>
    );
}
