import { useEffect, useState } from 'react';
import { Input, Modal, Typography } from '@jll/react-ui-components';

export function PresentationRenameModal({
    presentationName,
    open,
    onOk,
    onCancel,
}: {
    presentationName: string;
    open: boolean;
    onOk: (result: string) => void;
    onCancel: () => void;
}) {
    const [name, setName] = useState(presentationName);

    useEffect(() => {
        setName(presentationName);
    }, [presentationName]);

    return (
        <Modal title='Save Presentation' open={open} onOk={() => onOk(name)} onCancel={onCancel}>
            <Typography.Text>Enter a name for your presentation</Typography.Text>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Modal>
    );
}
