import { keyBy, values } from 'lodash';

const amenitiesData = {
    active: [
        {
            categories: ['fitness'],
            parent: { alias: 'active', title: 'Active' },
            title: 'Fitness',
        },
        { categories: ['parks'], parent: { alias: 'active', title: 'Active' }, title: 'Parks' },
        { categories: [], parent: { alias: 'active', title: 'Active' }, title: 'Other' },
    ],
    arts: [
        {
            categories: ['galleries'],
            parent: { alias: 'arts', title: 'Arts' },
            title: 'Galleries',
        },
        {
            categories: ['movietheaters'],
            parent: { alias: 'arts', title: 'Arts' },
            title: 'Movie Theaters',
        },
        { categories: ['museums'], parent: { alias: 'arts', title: 'Arts' }, title: 'Museums' },
        {
            categories: ['stadiumsarenas'],
            parent: { alias: 'arts', title: 'Arts' },
            title: 'Stadiums & Arenas',
        },
        { categories: [], parent: { alias: 'arts', title: 'Arts' }, title: 'Other' },
    ],
    auto: [
        {
            categories: ['autorepair'],
            parent: { alias: 'auto', title: 'Auto' },
            title: 'Auto Repair',
        },
        {
            categories: ['car_dealers'],
            parent: { alias: 'auto', title: 'Auto' },
            title: 'Car Dealers',
        },
        { categories: ['parking'], parent: { alias: 'auto', title: 'Auto' }, title: 'Parking' },
        {
            categories: ['servicestations'],
            parent: { alias: 'auto', title: 'Auto' },
            title: 'Gas Stations',
        },
        { categories: [], parent: { alias: 'auto', title: 'Auto' }, title: 'Other' },
    ],
    beautysvc: [
        {
            categories: ['beautysvc'],
            parent: { alias: 'beautysvc', title: 'Beauty' },
            title: 'Beauty',
        },
    ],
    education: [
        {
            categories: ['collegeuniv'],
            parent: { alias: 'education', title: 'Education' },
            title: 'Colleges & Universities',
        },
        {
            categories: ['highschools'],
            parent: { alias: 'education', title: 'Education' },
            title: 'High Schools',
        },
        { categories: [], parent: { alias: 'education', title: 'Education' }, title: 'Other' },
    ],
    eventservices: [
        {
            categories: ['eventservices'],
            parent: { alias: 'eventservices', title: 'Event Services' },
            title: 'Event Services',
        },
    ],
    financialservices: [
        {
            categories: ['banks'],
            parent: { alias: 'financialservices', title: 'Financial Services' },
            title: 'Banks',
        },
        {
            categories: ['insurance'],
            parent: { alias: 'financialservices', title: 'Financial Services' },
            title: 'Insurance',
        },
        {
            categories: [],
            parent: { alias: 'financialservices', title: 'Financial Services' },
            title: 'Other',
        },
    ],
    food: [
        {
            categories: ['bakeries'],
            parent: { alias: 'food', title: 'Food' },
            title: 'Bakeries',
        },
        { categories: ['coffee'], parent: { alias: 'food', title: 'Food' }, title: 'Coffee' },
        {
            categories: ['convenience'],
            parent: { alias: 'food', title: 'Food' },
            title: 'Convenience Stores',
        },
        { categories: ['grocery'], parent: { alias: 'food', title: 'Food' }, title: 'Grocery' },
        { categories: [], parent: { alias: 'food', title: 'Food' }, title: 'Other' },
    ],
    health: [
        {
            categories: ['dentists'],
            parent: { alias: 'health', title: 'Health' },
            title: 'Dentists',
        },
        {
            categories: ['physicians'],
            parent: { alias: 'health', title: 'Health' },
            title: 'Physicians',
        },
        {
            categories: ['hospitals'],
            parent: { alias: 'health', title: 'Health' },
            title: 'Hospitals',
        },
        {
            categories: ['pharmacy'],
            parent: { alias: 'health', title: 'Health' },
            title: 'Pharmacy',
        },
        { categories: [], parent: { alias: 'health', title: 'Health' }, title: 'Other' },
    ],
    homeservices: [
        {
            categories: ['homeservices'],
            parent: { alias: 'homeservices', title: 'Home Services' },
            title: 'Home Services',
        },
    ],
    localflavor: [
        {
            categories: ['localflavor'],
            parent: { alias: 'localflavor', title: 'Local Flavor' },
            title: 'Local Flavor',
        },
    ],
    localservices: [
        {
            categories: ['localservices'],
            parent: { alias: 'localservices', title: 'Local Services' },
            title: 'Local Services',
        },
    ],
    massmedia: [
        {
            categories: ['massmedia'],
            parent: { alias: 'massmedia', title: 'Mass Media' },
            title: 'Mass Media',
        },
    ],
    nightlife: [
        {
            categories: ['bars'],
            parent: { alias: 'nightlife', title: 'Night Life' },
            title: 'Bars',
        },
        {
            categories: ['musicvenues'],
            parent: { alias: 'nightlife', title: 'Night Life' },
            title: 'Music',
        },
        { categories: [], parent: { alias: 'nightlife', title: 'Night Life' }, title: 'Other' },
    ],
    pets: [
        {
            categories: ['petstore'],
            parent: { alias: 'pets', title: 'Pets' },
            title: 'Pet Stores',
        },
        {
            categories: ['vet'],
            parent: { alias: 'pets', title: 'Pets' },
            title: 'Veterinarians',
        },
        { categories: [], parent: { alias: 'pets', title: 'Pets' }, title: 'Other' },
    ],
    professional: [
        {
            categories: ['professional'],
            parent: { alias: 'professional', title: 'Professional' },
            title: 'Professional',
        },
    ],
    publicservicesgovt: [
        {
            categories: ['libraries'],
            parent: { alias: 'publicservicesgovt', title: 'Public Services' },
            title: 'Libraries',
        },
        {
            categories: ['policedepartments'],
            parent: { alias: 'publicservicesgovt', title: 'Public Services' },
            title: 'Police Departments',
        },
        {
            categories: ['courthouses'],
            parent: { alias: 'publicservicesgovt', title: 'Public Services' },
            title: 'Courthouses',
        },
        {
            categories: ['firedepartments'],
            parent: { alias: 'publicservicesgovt', title: 'Public Services' },
            title: 'Fire Departments',
        },
        {
            categories: ['postoffices'],
            parent: { alias: 'publicservicesgovt', title: 'Public Services' },
            title: 'Post Offices',
        },
        {
            categories: [],
            parent: { alias: 'publicservicesgovt', title: 'Public Services' },
            title: 'Other',
        },
    ],
    realestate: [
        {
            categories: ['realestate'],
            parent: { alias: 'realestate', title: 'Real Estate' },
            title: 'Real Estate',
        },
    ],
    religiousorgs: [
        {
            categories: ['religiousorgs'],
            parent: { alias: 'religiousorgs', title: 'Religious Organizations' },
            title: 'Religious Organizations',
        },
    ],
    restaurants: [
        {
            categories: ['restaurants'],
            parent: { alias: 'restaurants', title: 'Restaurants' },
            title: 'Restaurants',
        },
    ],
    shopping: [
        {
            categories: ['shopping'],
            parent: { alias: 'shopping', title: 'Shopping' },
            title: 'Shopping',
        },
    ],
    hotelstravel: [
        {
            categories: ['airports'],
            parent: { alias: 'hotelstravel', title: 'Travel' },
            title: 'Airports',
        },
        {
            categories: ['hotels'],
            parent: { alias: 'hotelstravel', title: 'Travel' },
            title: 'Hotels',
        },
        {
            categories: ['transport'],
            parent: { alias: 'hotelstravel', title: 'Travel' },
            title: 'Transportation',
        },
        { categories: [], parent: { alias: 'hotelstravel', title: 'Travel' }, title: 'Other' },
    ],
};

const icons = [
    {
        parentCategory: 'active',
        category: 'fitness',
        icon: 'active-fitness',
    },
    {
        parentCategory: 'active',
        category: 'gymnastics',
        icon: 'active-fitness',
    },
    {
        parentCategory: 'shopping',
        category: 'fitnessequipment',
        icon: 'active-fitness',
    },
    {
        parentCategory: 'active',
        category: 'amateursportsteams',
        icon: 'active-amateursportsteams',
    },
    {
        parentCategory: 'active',
        category: 'bocceball',
        icon: 'active-amateursportsteams',
    },
    {
        parentCategory: 'active',
        category: 'bubblesoccer',
        icon: 'active-amateursportsteams',
    },
    {
        parentCategory: 'active',
        category: 'football',
        icon: 'active-amateursportsteams',
    },
    {
        parentCategory: 'active',
        category: 'sports_clubs',
        icon: 'active-amateursportsteams',
    },
    {
        parentCategory: 'arts',
        category: 'sportsteams',
        icon: 'active-amateursportsteams',
    },
    {
        parentCategory: 'active',
        category: 'amusementparks',
        icon: 'active-amusementparks',
    },
    {
        parentCategory: 'active',
        category: 'aquariums',
        icon: 'active-aquariums',
    },
    {
        parentCategory: 'active',
        category: 'fishing',
        icon: 'active-aquariums',
    },
    {
        parentCategory: 'active',
        category: 'beach',
        icon: 'active-beach',
    },
    {
        parentCategory: 'active',
        category: 'bikeparking',
        icon: 'active-bikeparking',
    },
    {
        parentCategory: 'active',
        category: 'bikerentals',
        icon: 'active-bikeparking',
    },
    {
        parentCategory: 'active',
        category: 'mountainbiking',
        icon: 'active-bikeparking',
    },
    {
        parentCategory: 'localservices',
        category: 'bike_repair_maintenance',
        icon: 'active-bikeparking',
    },
    {
        parentCategory: 'shopping',
        category: 'sportgoods',
        icon: 'active-bikeparking',
    },
    {
        parentCategory: 'active',
        category: 'boating',
        icon: 'active-boating',
    },
    {
        parentCategory: 'active',
        category: 'lakes',
        icon: 'active-boating',
    },
    {
        parentCategory: 'active',
        category: 'sailing',
        icon: 'active-boating',
    },
    {
        parentCategory: 'auto',
        category: 'boatdealers',
        icon: 'active-boating',
    },
    {
        parentCategory: 'auto',
        category: 'boatpartsandsupplies',
        icon: 'active-boating',
    },
    {
        parentCategory: 'auto',
        category: 'marinas',
        icon: 'active-boating',
    },
    {
        parentCategory: 'active',
        category: 'bowling',
        icon: 'active-bowling',
    },
    {
        parentCategory: 'active',
        category: 'daycamps',
        icon: 'active-daycamps',
    },
    {
        parentCategory: 'active',
        category: 'summer_camps',
        icon: 'active-daycamps',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'campgrounds',
        icon: 'active-daycamps',
    },
    {
        parentCategory: '',
        category: 'generic',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'arts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'auto',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beautysvc',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'education',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'eventservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'financialservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'food',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'health',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homeservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hotelstravel',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'localflavor',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'localservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'massmedia',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nightlife',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'professional',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'publicservicesgovt',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'realestate',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'shopping',
        icon: 'generic',
    },
    {
        parentCategory: 'active',
        category: 'playgrounds',
        icon: 'active-playgrounds',
    },
    {
        parentCategory: 'active',
        category: 'recreation',
        icon: 'active-playgrounds',
    },
    {
        parentCategory: 'active',
        category: 'parks',
        icon: 'active-playgrounds',
    },
    {
        parentCategory: 'homeservices',
        category: 'treeservices',
        icon: 'active-playgrounds',
    },
    {
        parentCategory: 'localservices',
        category: 'forestry',
        icon: 'active-playgrounds',
    },
    {
        parentCategory: 'active',
        category: 'zoos',
        icon: 'active-zoos',
    },
    {
        parentCategory: 'arts',
        category: 'galleries',
        icon: 'arts-galleries',
    },
    {
        parentCategory: 'localservices',
        category: 'artrestoration',
        icon: 'arts-galleries',
    },
    {
        parentCategory: 'shopping',
        category: 'galleries',
        icon: 'arts-galleries',
    },
    {
        parentCategory: 'arts',
        category: 'movietheaters',
        icon: 'arts-movietheaters',
    },
    {
        parentCategory: 'professional',
        category: 'videofilmproductions',
        icon: 'arts-movietheaters',
    },
    {
        parentCategory: 'arts',
        category: 'museums',
        icon: 'arts-museums',
    },
    {
        parentCategory: 'arts',
        category: 'casinos',
        icon: 'arts-casinos',
    },
    {
        parentCategory: 'arts',
        category: 'gardens',
        icon: 'arts-gardens',
    },
    {
        parentCategory: 'eventservices',
        category: 'floraldesigners',
        icon: 'arts-gardens',
    },
    {
        parentCategory: 'homeservices',
        category: 'gardeners',
        icon: 'arts-gardens',
    },
    {
        parentCategory: 'shopping',
        category: 'flowers',
        icon: 'arts-gardens',
    },
    {
        parentCategory: 'shopping',
        category: 'homeandgarden',
        icon: 'arts-gardens',
    },
    {
        parentCategory: 'arts',
        category: 'wineries',
        icon: 'arts-wineries',
    },
    {
        parentCategory: 'food',
        category: 'beer_and_wine',
        icon: 'arts-wineries',
    },
    {
        parentCategory: 'food',
        category: 'breweries',
        icon: 'arts-wineries',
    },
    {
        parentCategory: 'food',
        category: 'distilleries',
        icon: 'arts-wineries',
    },
    {
        parentCategory: 'food',
        category: 'wineries',
        icon: 'arts-wineries',
    },
    {
        parentCategory: 'shopping',
        category: 'brewingsupplies',
        icon: 'arts-wineries',
    },
    {
        parentCategory: 'arts',
        category: 'stadiumsarenas',
        icon: 'arts-stadiumsarenas',
    },
    {
        parentCategory: 'auto',
        category: 'autorepair',
        icon: 'auto-autorepair',
    },
    {
        parentCategory: 'auto',
        category: 'car_dealers',
        icon: 'auto-car_dealers',
    },
    {
        parentCategory: 'auto',
        category: 'carbuyers',
        icon: 'auto-car_dealers',
    },
    {
        parentCategory: 'auto',
        category: 'usedcardealers',
        icon: 'auto-car_dealers',
    },
    {
        parentCategory: 'auto',
        category: 'servicestations',
        icon: 'auto-servicestations',
    },
    {
        parentCategory: 'auto',
        category: 'tservicestations',
        icon: 'auto-servicestations',
    },
    {
        parentCategory: 'auto',
        category: 'aircraftrepairs',
        icon: 'auto-aircraftrepairs',
    },
    {
        parentCategory: 'auto',
        category: 'aviationservices',
        icon: 'auto-aircraftrepairs',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'airports',
        icon: 'auto-aircraftrepairs',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'vacationrentalagents',
        icon: 'auto-aircraftrepairs',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'vacation_rentals',
        icon: 'auto-aircraftrepairs',
    },
    {
        parentCategory: 'auto',
        category: 'autoglass',
        icon: 'auto-autoglass',
    },
    {
        parentCategory: 'auto',
        category: 'autolaonproviders',
        icon: 'auto-autoglass',
    },
    {
        parentCategory: 'auto',
        category: 'autopartssupplies',
        icon: 'auto-autoglass',
    },
    {
        parentCategory: 'auto',
        category: 'autosecurity',
        icon: 'auto-autoglass',
    },
    {
        parentCategory: 'auto',
        category: 'autoupholstery',
        icon: 'auto-autoglass',
    },
    {
        parentCategory: 'auto',
        category: 'auto_detailing',
        icon: 'auto-autoglass',
    },
    {
        parentCategory: 'auto',
        category: 'carshares',
        icon: 'auto-autoglass',
    },
    {
        parentCategory: 'publicservicesgovt',
        category: 'departmentofmotorvehicles',
        icon: 'auto-autoglass',
    },
    {
        parentCategory: 'auto',
        category: 'carwash',
        icon: 'auto-carwash',
    },
    {
        parentCategory: 'auto',
        category: 'motorcycledealers',
        icon: 'auto-motorcycledealers',
    },
    {
        parentCategory: 'auto',
        category: 'motorcyclerepair',
        icon: 'auto-motorcycledealers',
    },
    {
        parentCategory: 'auto',
        category: 'rvrepair',
        icon: 'auto-rvrepair',
    },
    {
        parentCategory: 'auto',
        category: 'rv_dealers',
        icon: 'auto-rvrepair',
    },
    {
        parentCategory: 'auto',
        category: 'trailerdealers',
        icon: 'auto-rvrepair',
    },
    {
        parentCategory: 'auto',
        category: 'trailerrental',
        icon: 'auto-rvrepair',
    },
    {
        parentCategory: 'auto',
        category: 'trailerrepair',
        icon: 'auto-rvrepair',
    },
    {
        parentCategory: 'homeservices',
        category: 'mobile_home_repair',
        icon: 'auto-rvrepair',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'rvparks',
        icon: 'auto-rvrepair',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'rvrental',
        icon: 'auto-rvrepair',
    },
    {
        parentCategory: 'realestate',
        category: 'mobilehomes',
        icon: 'auto-rvrepair',
    },
    {
        parentCategory: 'auto',
        category: 'truckdealers',
        icon: 'auto-truckdealers',
    },
    {
        parentCategory: 'auto',
        category: 'truckrepair',
        icon: 'auto-truckdealers',
    },
    {
        parentCategory: 'auto',
        category: 'truck_rental',
        icon: 'auto-truckdealers',
    },
    {
        parentCategory: 'homeservices',
        category: 'movers',
        icon: 'auto-truckdealers',
    },
    {
        parentCategory: 'localservices',
        category: 'couriers',
        icon: 'auto-truckdealers',
    },
    {
        parentCategory: 'auto',
        category: 'parking',
        icon: 'auto-parking',
    },
    {
        parentCategory: 'beautysvc',
        category: 'barbers',
        icon: 'beautysvc-barbers',
    },
    {
        parentCategory: 'beautysvc',
        category: 'hair',
        icon: 'beautysvc-barbers',
    },
    {
        parentCategory: 'beautysvc',
        category: 'cosmetics',
        icon: 'beautysvc-cosmetics',
    },
    {
        parentCategory: 'beautysvc',
        category: 'makeupartists',
        icon: 'beautysvc-cosmetics',
    },
    {
        parentCategory: 'beautysvc',
        category: 'permanentmakeup',
        icon: 'beautysvc-cosmetics',
    },
    {
        parentCategory: 'shopping',
        category: 'cosmetics',
        icon: 'beautysvc-cosmetics',
    },
    {
        parentCategory: 'beautysvc',
        category: 'madicalspa',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'beautysvc',
        category: 'massage',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'beautysvc',
        category: 'spas',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'health',
        category: 'acupuncture',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'health',
        category: 'alternativemedicine',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'health',
        category: 'floatspa',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'health',
        category: 'halotherapy',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'health',
        category: 'massage_therapy',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'health',
        category: 'medicalspa',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'health',
        category: 'reiki',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'healthretreats',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'shopping',
        category: 'spiritual_shop',
        icon: 'beautysvc-madicalspa',
    },
    {
        parentCategory: 'beautysvc',
        category: 'othersalons',
        icon: 'beautysvc-othersalons',
    },
    {
        parentCategory: 'beautysvc',
        category: 'teethwhitening',
        icon: 'beautysvc-teethwhitening',
    },
    {
        parentCategory: 'health',
        category: 'dentists',
        icon: 'beautysvc-teethwhitening',
    },
    {
        parentCategory: 'health',
        category: 'dentalhygienists',
        icon: 'beautysvc-teethwhitening',
    },
    {
        parentCategory: 'education',
        category: 'collegeuniv',
        icon: 'education-collegeuniv',
    },
    {
        parentCategory: 'education',
        category: 'highschools',
        icon: 'education-highschools',
    },
    {
        parentCategory: 'education',
        category: 'elementaryschools',
        icon: 'education-highschools',
    },
    {
        parentCategory: 'education',
        category: 'montessori',
        icon: 'education-highschools',
    },
    {
        parentCategory: 'education',
        category: 'preschools',
        icon: 'education-highschools',
    },
    {
        parentCategory: 'education',
        category: 'specialtyschools',
        icon: 'education-highschools',
    },
    {
        parentCategory: 'education',
        category: 'waldorfschools',
        icon: 'education-highschools',
    },
    {
        parentCategory: 'education',
        category: 'educationservices',
        icon: 'education-educationservices',
    },
    {
        parentCategory: 'education',
        category: 'tutoring',
        icon: 'education-educationservices',
    },
    {
        parentCategory: 'localservices',
        category: 'bookbinding',
        icon: 'education-educationservices',
    },
    {
        parentCategory: 'localservices',
        category: 'communitybookbox',
        icon: 'education-educationservices',
    },
    {
        parentCategory: 'eventservices',
        category: 'balloonservices',
        icon: 'eventservices-balloonservices',
    },
    {
        parentCategory: 'eventservices',
        category: 'clowns',
        icon: 'eventservices-balloonservices',
    },
    {
        parentCategory: 'eventservices',
        category: 'partycharacters',
        icon: 'eventservices-balloonservices',
    },
    {
        parentCategory: 'eventservices',
        category: 'partysupplies',
        icon: 'eventservices-balloonservices',
    },
    {
        parentCategory: 'eventservices',
        category: 'bartenders',
        icon: 'eventservices-bartenders',
    },
    {
        parentCategory: 'nightlife',
        category: 'bars',
        icon: 'eventservices-bartenders',
    },
    {
        parentCategory: 'eventservices',
        category: 'catering',
        icon: 'eventservices-catering',
    },
    {
        parentCategory: '',
        category: 'restaurants',
        icon: 'eventservices-catering',
    },
    {
        parentCategory: 'eventservices',
        category: 'djs',
        icon: 'eventservices-djs',
    },
    {
        parentCategory: 'eventservices',
        category: 'silentdisco',
        icon: 'eventservices-djs',
    },
    {
        parentCategory: 'nightlife',
        category: 'danceclubs',
        icon: 'eventservices-djs',
    },
    {
        parentCategory: 'eventservices',
        category: 'hotels',
        icon: 'eventservices-hotels',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'hotels',
        icon: 'eventservices-hotels',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'bedbreakfast',
        icon: 'eventservices-hotels',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'guesthouses',
        icon: 'eventservices-hotels',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'hostels',
        icon: 'eventservices-hotels',
    },
    {
        parentCategory: 'eventservices',
        category: 'officiants',
        icon: 'eventservices-officiants',
    },
    {
        parentCategory: 'eventservices',
        category: 'wedding_planning',
        icon: 'eventservices-officiants',
    },
    {
        parentCategory: 'shopping',
        category: 'bridal',
        icon: 'eventservices-officiants',
    },
    {
        parentCategory: 'financialservices',
        category: 'banks',
        icon: 'financialservices-banks',
    },
    {
        parentCategory: 'financialservices',
        category: 'insurance',
        icon: 'financialservices-insurance',
    },
    {
        parentCategory: 'financialservices',
        category: 'businessfinancing',
        icon: 'financialservices-businessfinancing',
    },
    {
        parentCategory: 'professional',
        category: 'businessconsulting',
        icon: 'financialservices-businessfinancing',
    },
    {
        parentCategory: 'financialservices',
        category: 'financialadvising',
        icon: 'financialservices-financialadvising',
    },
    {
        parentCategory: 'professional',
        category: 'payroll',
        icon: 'financialservices-financialadvising',
    },
    {
        parentCategory: 'financialservices',
        category: 'taxservices',
        icon: 'financialservices-taxservices',
    },
    {
        parentCategory: 'professional',
        category: 'accountants',
        icon: 'financialservices-taxservices',
    },
    {
        parentCategory: 'professional',
        category: 'bookkepers',
        icon: 'financialservices-taxservices',
    },
    {
        parentCategory: 'food',
        category: 'bakeries',
        icon: 'food-bakeries',
    },
    {
        parentCategory: 'food',
        category: 'bagels',
        icon: 'food-bakeries',
    },
    {
        parentCategory: 'food',
        category: 'donuts',
        icon: 'food-bakeries',
    },
    {
        parentCategory: 'food',
        category: 'coffee',
        icon: 'food-coffee',
    },
    {
        parentCategory: 'food',
        category: 'coffeeroasteries',
        icon: 'food-coffee',
    },
    {
        parentCategory: 'food',
        category: 'coffeeteasupplies',
        icon: 'food-coffee',
    },
    {
        parentCategory: 'food',
        category: 'internetcafe',
        icon: 'food-coffee',
    },
    {
        parentCategory: 'food',
        category: 'tea',
        icon: 'food-coffee',
    },
    {
        parentCategory: 'food',
        category: 'convenience',
        icon: 'food-convenience',
    },
    {
        parentCategory: 'food',
        category: 'grocery',
        icon: 'food-convenience',
    },
    {
        parentCategory: 'food',
        category: 'ethicgrocery',
        icon: 'food-convenience',
    },
    {
        parentCategory: 'food',
        category: 'intlgrocery',
        icon: 'food-convenience',
    },
    {
        parentCategory: 'food',
        category: 'organic_stores',
        icon: 'food-convenience',
    },
    {
        parentCategory: 'food',
        category: 'cakeshop',
        icon: 'food-cakeshop',
    },
    {
        parentCategory: 'food',
        category: 'customcakes',
        icon: 'food-cakeshop',
    },
    {
        parentCategory: 'food',
        category: 'delicatessen',
        icon: 'food-cakeshop',
    },
    {
        parentCategory: 'food',
        category: 'desserts',
        icon: 'food-desserts',
    },
    {
        parentCategory: 'food',
        category: 'icecream',
        icon: 'food-desserts',
    },
    {
        parentCategory: 'food',
        category: 'fooddeliveryservices',
        icon: 'food-fooddeliveryservices',
    },
    {
        parentCategory: 'food',
        category: 'foodtrucks',
        icon: 'food-foodtrucks',
    },
    {
        parentCategory: 'food',
        category: 'streetvendors',
        icon: 'food-foodtrucks',
    },
    {
        parentCategory: 'health',
        category: 'hospitals',
        icon: 'health-hospitals',
    },
    {
        parentCategory: 'health',
        category: 'medcenters',
        icon: 'health-hospitals',
    },
    {
        parentCategory: 'health',
        category: 'diagnosticservices',
        icon: 'health-diagnosticservices',
    },
    {
        parentCategory: 'health',
        category: 'ultrasoundimagingcenters',
        icon: 'health-diagnosticservices',
    },
    {
        parentCategory: 'health',
        category: 'emergencyrooms',
        icon: 'health-emergencyrooms',
    },
    {
        parentCategory: 'health',
        category: 'healthcoach',
        icon: 'health-emergencyrooms',
    },
    {
        parentCategory: 'health',
        category: 'nursepractitioner',
        icon: 'health-emergencyrooms',
    },
    {
        parentCategory: 'health',
        category: 'urgent_care',
        icon: 'health-emergencyrooms',
    },
    {
        parentCategory: 'health',
        category: 'physicians',
        icon: 'health-emergencyrooms',
    },
    {
        parentCategory: 'pets',
        category: 'vet',
        icon: 'health-emergencyrooms',
    },
    {
        parentCategory: 'health',
        category: 'physicaltherapy',
        icon: 'health-physicaltherapy',
    },
    {
        parentCategory: 'health',
        category: 'pharmacy',
        icon: 'health-pharmacy',
    },
    {
        parentCategory: 'shopping',
        category: 'medicalsupplies',
        icon: 'health-pharmacy',
    },
    {
        parentCategory: 'homeservices',
        category: 'buildingsupplies',
        icon: 'homeservices-buildingsupplies',
    },
    {
        parentCategory: 'homeservices',
        category: 'contractors',
        icon: 'homeservices-buildingsupplies',
    },
    {
        parentCategory: 'homeservices',
        category: 'handyman',
        icon: 'homeservices-buildingsupplies',
    },
    {
        parentCategory: 'homeservices',
        category: 'electricians',
        icon: 'homeservices-electricians',
    },
    {
        parentCategory: 'homeservices',
        category: 'lighting',
        icon: 'homeservices-electricians',
    },
    {
        parentCategory: 'homeservices',
        category: 'fireplace',
        icon: 'homeservices-fireplace',
    },
    {
        parentCategory: 'homeservices',
        category: 'fireprotection',
        icon: 'homeservices-fireplace',
    },
    {
        parentCategory: 'homeservices',
        category: 'firewood',
        icon: 'homeservices-fireplace',
    },
    {
        parentCategory: 'localservices',
        category: 'propane',
        icon: 'homeservices-fireplace',
    },
    {
        parentCategory: 'homeservices',
        category: 'hometheatreinstallation',
        icon: 'homeservices-hometheatreinstallation',
    },
    {
        parentCategory: 'homeservices',
        category: 'televisionserviceproviders',
        icon: 'homeservices-hometheatreinstallation',
    },
    {
        parentCategory: 'localservices',
        category: 'tvmounting',
        icon: 'homeservices-hometheatreinstallation',
    },
    {
        parentCategory: 'homeservices',
        category: 'locksmiths',
        icon: 'homeservices-locksmiths',
    },
    {
        parentCategory: 'homeservices',
        category: 'packingservices',
        icon: 'homeservices-packingservices',
    },
    {
        parentCategory: 'shopping',
        category: 'packingsupplies',
        icon: 'homeservices-packingservices',
    },
    {
        parentCategory: 'homeservices',
        category: 'painters',
        icon: 'homeservices-painters',
    },
    {
        parentCategory: 'homeservices',
        category: 'plumbing',
        icon: 'homeservices-plumbing',
    },
    {
        parentCategory: 'homeservices',
        category: 'waterpurification',
        icon: 'homeservices-plumbing',
    },
    {
        parentCategory: 'homeservices',
        category: 'realestate',
        icon: 'homeservices-realestate',
    },
    {
        parentCategory: 'realestate',
        category: 'realestateagents',
        icon: 'homeservices-realestate',
    },
    {
        parentCategory: 'realestate',
        category: 'realestatesvcs',
        icon: 'homeservices-realestate',
    },
    {
        parentCategory: 'homeservices',
        category: 'roofing',
        icon: 'homeservices-roofing',
    },
    {
        parentCategory: 'homeservices',
        category: 'securitysystems',
        icon: 'homeservices-securitysystems',
    },
    {
        parentCategory: 'professional',
        category: 'security',
        icon: 'homeservices-securitysystems',
    },
    {
        parentCategory: 'publicservicesgovt',
        category: 'policedepartments',
        icon: 'homeservices-securitysystems',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'carrental',
        icon: 'hotelstravel-carrental',
    },
    {
        parentCategory: 'hotelstravel',
        category: 'trainstations',
        icon: 'hotelstravel-trainstations',
    },
    {
        parentCategory: 'localservices',
        category: 'busrental',
        icon: 'localservices-busrental',
    },
    {
        parentCategory: 'localservices',
        category: 'electronicsrepair',
        icon: 'localservices-electronicsrepair',
    },
    {
        parentCategory: 'localservices',
        category: 'itservices',
        icon: 'localservices-electronicsrepair',
    },
    {
        parentCategory: 'shopping',
        category: 'computers',
        icon: 'localservices-electronicsrepair',
    },
    {
        parentCategory: 'shopping',
        category: 'electronics',
        icon: 'localservices-electronicsrepair',
    },
    {
        parentCategory: 'localservices',
        category: 'funeralservices',
        icon: 'localservices-funeralservices',
    },
    {
        parentCategory: 'localservices',
        category: 'furniturerepair',
        icon: 'localservices-furniturerepair',
    },
    {
        parentCategory: 'localservices',
        category: 'rentfurniture',
        icon: 'localservices-furniturerepair',
    },
    {
        parentCategory: 'localservices',
        category: 'reupholstery',
        icon: 'localservices-furniturerepair',
    },
    {
        parentCategory: 'shopping',
        category: 'antiques',
        icon: 'localservices-furniturerepair',
    },
    {
        parentCategory: 'localservices',
        category: 'hazardouswastedisposal',
        icon: 'localservices-hazardouswastedisposal',
    },
    {
        parentCategory: 'localservices',
        category: 'homeappliancerepair',
        icon: 'localservices-homeappliancerepair',
    },
    {
        parentCategory: 'localservices',
        category: 'laundryservices',
        icon: 'localservices-homeappliancerepair',
    },
    {
        parentCategory: 'localservices',
        category: 'jewelryrepair',
        icon: 'localservices-jewelryrepair',
    },
    {
        parentCategory: 'shopping',
        category: 'gemstonesandminerals',
        icon: 'localservices-jewelryrepair',
    },
    {
        parentCategory: 'shopping',
        category: 'goldbuyers',
        icon: 'localservices-jewelryrepair',
    },
    {
        parentCategory: 'shopping',
        category: 'jewelry',
        icon: 'localservices-jewelryrepair',
    },
    {
        parentCategory: 'localservices',
        category: 'recyclingcenter',
        icon: 'localservices-recyclingcenter',
    },
    {
        parentCategory: 'localservices',
        category: 'selfstorage',
        icon: 'localservices-selfstorage',
    },
    {
        parentCategory: 'localservices',
        category: 'shipping_centers',
        icon: 'localservices-selfstorage',
    },
    {
        parentCategory: 'localservices',
        category: 'shoerepair',
        icon: 'localservices-shoerepair',
    },
    {
        parentCategory: 'localservices',
        category: 'shoeshine',
        icon: 'localservices-shoerepair',
    },
    {
        parentCategory: 'localservices',
        category: 'wildlifecontrol',
        icon: 'localservices-wildlifecontrol',
    },
    {
        parentCategory: 'pets',
        category: 'animalshelters',
        icon: 'localservices-wildlifecontrol',
    },
    {
        parentCategory: 'pets',
        category: 'petadoption',
        icon: 'localservices-wildlifecontrol',
    },
    {
        parentCategory: 'pets',
        category: 'petservices',
        icon: 'localservices-wildlifecontrol',
    },
    {
        parentCategory: 'pets',
        category: 'petstore',
        icon: 'localservices-wildlifecontrol',
    },
    {
        parentCategory: 'professional',
        category: 'lawyers',
        icon: 'professional-lawyers',
    },
    {
        parentCategory: 'professional',
        category: 'legalservices',
        icon: 'professional-lawyers',
    },
    {
        parentCategory: 'professional',
        category: 'privateinvestigation',
        icon: 'professional-privateinvestigation',
    },
    {
        parentCategory: 'publicservicesgovt',
        category: 'courthouses',
        icon: 'publicservicesgovt-courthouses',
    },
    {
        parentCategory: 'publicservicesgovt',
        category: 'firedepartments',
        icon: 'publicservicesgovt-firedepartments',
    },
    {
        parentCategory: 'publicservicesgovt',
        category: 'libraries',
        icon: 'publicservicesgovt-libraries',
    },
    {
        parentCategory: 'publicservicesgovt',
        category: 'embassy',
        icon: 'publicservicesgovt-embassy',
    },
    {
        parentCategory: 'publicservicesgovt',
        category: 'postoffices',
        icon: 'publicservicesgovt-postoffices',
    },
    {
        parentCategory: 'realestate',
        category: 'apartments',
        icon: 'realestate-apartments',
    },
    {
        parentCategory: 'realestate',
        category: 'commercialrealestate',
        icon: 'realestate-apartments',
    },
    {
        parentCategory: 'realestate',
        category: 'condominiums',
        icon: 'realestate-condominiums',
    },
    {
        parentCategory: 'religiousorgs',
        category: 'buddhist_temples',
        icon: 'religiousorgs-buddhist_temples',
    },
    {
        parentCategory: 'religiousorgs',
        category: 'churches',
        icon: 'religiousorgs-churches',
    },
    {
        parentCategory: 'shopping',
        category: 'religiousitems',
        icon: 'religiousorgs-churches',
    },
    {
        parentCategory: 'religiousorgs',
        category: 'hindu_temples',
        icon: 'religiousorgs-hindu_temples',
    },
    {
        parentCategory: 'religiousorgs',
        category: 'mosques',
        icon: 'religiousorgs-mosques',
    },
    {
        parentCategory: 'religiousorgs',
        category: 'sikhtemples',
        icon: 'religiousorgs-sikhtemples',
    },
    {
        parentCategory: 'religiousorgs',
        category: 'synagogues',
        icon: 'religiousorgs-synagogues',
    },
    {
        parentCategory: 'shopping',
        category: 'cellphoneaccessories',
        icon: 'shopping-cellphoneaccessories',
    },
    {
        parentCategory: 'shopping',
        category: 'mobilephones',
        icon: 'shopping-cellphoneaccessories',
    },
    {
        parentCategory: 'shopping',
        category: 'deptstores',
        icon: 'shopping-deptstores',
    },
    {
        parentCategory: 'shopping',
        category: 'outlet_stores',
        icon: 'shopping-deptstores',
    },
    {
        parentCategory: 'shopping',
        category: 'personal_shopping',
        icon: 'shopping-deptstores',
    },
    {
        parentCategory: 'shopping',
        category: 'shoppingcenters',
        icon: 'shopping-deptstores',
    },
    {
        parentCategory: 'shopping',
        category: 'shoppingpassages',
        icon: 'shopping-deptstores',
    },
    {
        parentCategory: 'shopping',
        category: 'discountstore',
        icon: 'shopping-discountstore',
    },
    {
        parentCategory: 'shopping',
        category: 'fashion',
        icon: 'shopping-fashion',
    },
    {
        parentCategory: 'shopping',
        category: 'luggage',
        icon: 'shopping-luggage',
    },
    {
        parentCategory: 'shopping',
        category: 'toys',
        icon: 'shopping-toys',
    },
    {
        parentCategory: '',
        category: '3dprinting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'abruzzese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'absinthebars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'acaibowls',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'accessories',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'acnetreatment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'active',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'addictionmedicine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'adoptionservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'adult',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'adultedu',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'adultentertainment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'advertising',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'aerialfitness',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'aerialtours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'aestheticians',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'afghani',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'african',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'afrobrazilian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'agriturismi',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'aircraftdealers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'airductcleaning',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'airlines',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'airport_shuttles',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'airportlounges',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'airportterminals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'airsoft',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'alentejo',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'algarve',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'allergist',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'alsatian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'altoatesine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'andalusian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'anesthesiologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'animalassistedtherapy',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'animalholistic',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'animalphysicaltherapy',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'apartmentagents',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'appliances',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'appraisalservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'apulian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'aquariumservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'arabian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'arabpizza',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'arcades',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'archery',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'architects',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'architecturaltours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'argentine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'armenian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'arroceria_paella',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'artclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'artconsultants',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'artificialturf',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'artinstallation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'artmuseums',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'artsandcrafts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'artschools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'artspacerentals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'artsupplies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'arttours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'asianfusion',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'assistedliving',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'astrologers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'asturian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ateliers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'attractionfarms',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'atvrentals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'auctionhouses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'audiologist',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'audiovisualequipmentrental',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'australian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'austrian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'authorized_postal_representative',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'autocustomization',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'autodamageassessment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'autoelectric',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'autoinsurance',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'autoloanproviders',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'auvergnat',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'awnings',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'axethrowing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ayurveda',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'azores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'baby_gear',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'backflowservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'backshop',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'baden',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'badminton',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'baguettes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bailbondsmen',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bangladeshi',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bankruptcy',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'barcrawl',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'barreclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bartendingschools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'baseballfields',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'basketballcourts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'basque',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bathing_area',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'batterystores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'battingcages',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bavarian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bbq',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beachbars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beachequipmentrental',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beaches',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beachvolleyball',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beerbar',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beergarden',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beergardens',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beerhall',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beertours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'behavioranalysts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beira',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beisl',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'belgian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bento',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'berrichon',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bespoke',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bettingcenters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'beverage_stores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bicyclepaths',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bicycles',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bikeassociations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bikerepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bikes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bikesharing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bikeshop',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'biketours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'billingservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bingo',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'biohazardcleanup',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'birdshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bistros',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'blacksea',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'blinds',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'blooddonation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'blowfish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'blowoutservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'boatcharters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'boatrepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'boattours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bobsledding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bodycontouring',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bodyshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bookkeepers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bookstores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bootcamps',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'boudoirphotography',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bouncehouserentals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bourguignon',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'boxing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'brasseries',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'brazilian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'brazilianempanadas',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'brazilianjiujitsu',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'breakfast_brunch',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'brewpubs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'british',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bubbletea',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'buffets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bulgarian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bulkbilling',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bungeejumping',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'burgers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'burmese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'buses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'businesslawyers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'busstations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'bustours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'butcher',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'c_and_mh',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cabaret',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cabinetry',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cablecars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cafes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cafeteria',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cajun',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'calabrian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'calligraphy',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cambodian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'candlestores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'candy',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cannabis_clinics',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cannabiscollective',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cannabisdispensaries',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cannabisreferrals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cannabistours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'canteen',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cantonese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'canyoneering',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'carauctions',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'carbrokers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cardioclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cardiology',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'careercounseling',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'caribbean',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'caricatures',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'carousels',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'carpenters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'carpet_cleaning',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'carpetdyeing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'carpeting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'carpetinstallation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'carwindowtinting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'castles',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'catalan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'centralbrazilian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ceremonialclothing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'challengecourses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'champagne_bars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'chech',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cheekufta',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cheerleading',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cheese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cheesesteaks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cheesetastingclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'chicken_wings',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'chickenshop',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'childbirthedu',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'childcare',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'childcloth',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'childproofing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'childrensmuseums',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'chilean',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'chimneycakes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'chimneysweeps',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'chinese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'chinesebazaar',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'chinesemartialarts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'chiropractors',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'chocolate',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'choirs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'christmastrees',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'churros',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cideries',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cigarbars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'circuittraininggyms',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'circusschools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'civiccenter',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'climbing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'clockrepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'clothingrental',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'clubcrawl',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cocktailbars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'coffeeshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'collegecounseling',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'colombian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'colonics',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'comedyclubs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'comfortfood',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'comicbooks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'commissionedartists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'communitycenters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'communitygardens',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'concept_shops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'conciergemedicine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'congee',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'consumerlaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'contractlaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'conveyorsushi',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cookingclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cookingschools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'copyshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'corsican',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cosmeticdentists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cosmeticsurgeons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cosmetology_schools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'costumes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'countertopinstall',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'countryclubs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'countrydancehalls',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'courtreporters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cprclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'craneservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cremationservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'creperies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'criminaldefense',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'crisispregnancycenters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cryotherapy',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'csa',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cuban',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cucinacampana',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'culturalcenter',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cupcakes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'currencyexchange',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'currysausage',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'custommerchandise',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'customsbrokers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cyclingclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'cypriot',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'czech',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'czechslovakian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dagashi',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'damagerestoration',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dance_schools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dancerestaurants',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dancestudio',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dancewear',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'danish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dartarenas',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'datarecovery',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'debtrelief',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'decksrailing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'delis',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'demolitionservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dentalhygeiniststorefront',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dentalhygienistsmobile',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'departmentsofmotorvehicles',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dermatology',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'diagnosticimaging',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dialysisclinics',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'diamondbuyers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dietitians',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'digitizingservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dimsum',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'diners',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dinnertheater',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'disabilitylaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'discgolf',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'divebars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'diveshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'diving',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'divorce',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'diyautoshop',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'diyfood',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dog_parks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dogwalkers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dolmusstation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dominican',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'donairs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'donationcenter',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'donburi',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'doorsales',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'doulas',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dramaschools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'driedfruit',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'driveintheater',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'drivethrubars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'driving_schools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'drones',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'drugstores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dryclean',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'drycleaninglaundry',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'drywall',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'duilawyers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'duischools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dumplings',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dumpsterrental',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'duplicationservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'dutyfreeshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'earnosethroat',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'eastern_european',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'easterngerman',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'easternmexican',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'eatertainment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'editorialservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'egyptian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'eldercareplanning',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'elderlaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'electricitysuppliers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'elevatorservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'eltern_cafes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'embroideryandcrochet',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'emergencymedicine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'emergencypethospital',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'emilian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'empanadas',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'employmentagencies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'employmentlawyers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'emstraining',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'endocrinologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'endodontists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'engraving',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'entertainmentlaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'enviroabatement',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'environmentaltesting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'eritrean',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'eroticmassage',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'escapegames',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'estateliquidation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'estatephotography',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'estateplanning',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'estheticians',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ethiopian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ethnicgrocery',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ethnicmarkets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'evchargingstations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'eventphotography',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'eventplanning',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'excavationservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'experiences',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'eyebrowservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'eyelashservice',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fabricstores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'facepainting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fado_houses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'faithbasedcpc',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'falafel',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'familydr',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'farmequipmentrepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'farmersmarket',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'farmingequipment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'farms',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'farriers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fasil',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fencesgates',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fencing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fengshui',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ferries',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fertility',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'festivals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'filipino',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fingerprinting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'firearmtraining',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fireworks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'firstaidclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fischbroetchen',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fishmonger',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fishnchips',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'flatbread',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fleamarkets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'flemish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'flightinstruction',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'flooring',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'florists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'flowersonly',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'flyboarding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fondue',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'food_court',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'foodbanks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'foodsafety',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'foodstands',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'foodtours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'footcare',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'formalwear',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'foundationrepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'framing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'franconian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'freediving',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'freiduria',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'french',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'friterie',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'friulan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'frozenfood',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fueldocks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'funfair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'furclothing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'furniture',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'furnitureassembly',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'fuzhou',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'galician',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gamemeat',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gametruckrental',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'garage_door_services',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gardening',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gasstations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gastroenterologist',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gastropubs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gaybars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gelato',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'general_litigation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'generaldentistry',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'generalfestivals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'generatorinstallrepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'geneticists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'georgian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'german',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gerontologist',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gestorias',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'giblets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'giftshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'glassandmirrors',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'glassblowing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gliding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gluhwein',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gluten_free',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gokarts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'golf',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'golfcartdealers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'golfcartrentals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'golfequipment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'golflessons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'golfshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gourmet',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gozleme',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'graphicdesign',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'greek',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'grillingequipment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'grillservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'groomer',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'groutservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'guamanian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'guitarstores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gun_ranges',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'guns_and_ammo',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gunsmith',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gutterservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gyms',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'gyudon',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'habilitativeservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hainan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hair_extensions',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hairloss',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hairremoval',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hairstylists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'haitian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hakka',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'halal',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'halfwayhouses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'handball',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'handrolls',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hanggliding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hardware',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hats',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hauntedhouses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hawaiian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hawkercentre',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'headshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'healthinsurance',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'healthmarkets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'healthtrainers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hearing_aids',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hearingaidproviders',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'henghwa',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hennaartists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hepatologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'herbalshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'herbsandspices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hessian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'heuriger',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hifi',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hiking',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'himalayan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'historicaltours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hkcafe',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hobbyshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hockeyequipment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hokkien',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'holidaydecorations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'home_inspectors',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'home_organization',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homeautomation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homecleaning',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homedecor',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homedevelopers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homeenergyauditors',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homehealthcare',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homeinsurance',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homelessshelters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homemadefood',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homenetworkinstall',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homeopathic',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homeownerassociation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homestaging',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'homewindowtinting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'honduran',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'honey',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hookah_bars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'horse_boarding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'horsebackriding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'horsequipment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'horseracing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'horumon',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hospice',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hospitalists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hot_air_balloons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hotdog',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hotdogs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hotel_bar',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hotpot',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hotsprings',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hottubandpool',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'housesitters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'housingcooperatives',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hunan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hungarian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'huntingfishingsupplies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hvac',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hybridcarrepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hydrojetting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hydroponics',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hydrotherapy',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'hypnosis',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'iberian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'icedelivery',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'immigrationlawyers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'immunodermatologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'importedfood',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'indonesian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'indoor_playcenter',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'indoorlandscaping',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'indpak',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'infectiousdisease',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'installmentloans',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'insulationinstallation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'interiordesign',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'interlocksystems',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'internalmed',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'international',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'internetbooth',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'intervaltraininggyms',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'investing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'iplaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'irish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'irish_pubs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'irrigation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'island_pub',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'isps',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'israeli',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'italian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ivhydration',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'izakaya',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'jailsandprisons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'jaliscan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'japacurry',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'japanese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'jazzandblues',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'jetskis',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'jewish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'jpsweets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'juicebars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'junkremovalandhauling',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'junkyards',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kaiseki',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'karaoke',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'karaokerental',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'karate',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kebab',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kickboxing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kids_activities',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kidshairsalons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kimonos',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kiosk',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kitchenandbath',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kitchenincubators',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kitchensupplies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kiteboarding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'knifesharpening',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'knittingsupplies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kombucha',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kopitiam',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'korean',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kosher',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kurdish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'kushikatsu',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'laboratorytesting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lactationservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lahmacun',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lancenters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'landmarks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'landscapearchitects',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'landscaping',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'landsurveying',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'language_schools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'laos',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'laotian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'laser_hair_removal',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'laserlasikeyes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lasertag',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'latin',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'laundromat',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lawn_bowling',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lawnservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'leather',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lebanese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'leisure_centers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'liceservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lifecoach',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lifeinsurance',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lightingstores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ligurian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'limos',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'linens',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lingerie',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'livestocksupply',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'localfishstores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lounges',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'luggagestorage',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lumbard',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'lyonnais',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'macarons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'machinerental',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'machineshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'madeira',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'magicians',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mags',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mahjong',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mailboxcenters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'makerspaces',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'malaysian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mamak',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'marchingbands',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'marketing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'markets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'marketstalls',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'martialarts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'masonry_concrete',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'massage_schools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'matchmakers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'materialeelettrico',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'maternity',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mattresses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mauritius',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'meaderies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'meatballs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'meats',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'media',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mediators',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'medicalfoot',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'medicallaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'medicaltransportation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'meditationcenters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mediterranean',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'memorycare',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'menscloth',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'menshair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'metaldetectorservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'metalfabricators',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'metrostations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mexican',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mideastern',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'midwives',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'militarysurplus',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'milkbars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'milkshakebars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'minho',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mini_golf',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mistingsystemservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mobiledentrepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mobileparks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mobilephonerepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mobilityequipment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'modern_australian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'modern_european',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mohels',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mongolian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'moroccan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mortgagebrokers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mortgagelenders',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mortuaryservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'motodealers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'motorcycle_rental',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'motorcyclepartsandsupplies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'motorcyclinggear',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'motorepairs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mountainhuts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'muaythai',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'municipality',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'musicalinstrumentsandteachers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'musicians',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'musicinstrumentservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'musicproduction',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'musicvenues',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'musicvideo',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'mystics',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nailtechnicians',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nannys',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'napoletana',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nasilemak',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'naturalgassuppliers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'naturopathic',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nephrologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'neurologist',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'neuropathologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'neurotologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'newamerican',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'newcanadian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'newmexican',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'newzealand',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nicaraguan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nicois',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nightfood',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nikkei',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nonprofit',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'noodles',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'norcinerie',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'northeasternbrazilian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'northernbrazilian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'northerngerman',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'northernmexican',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'norwegian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'notaries',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nudist',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nursingschools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nutritionists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'nyonya',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'oaxacan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'obgyn',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'observatories',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'occupationaltherapy',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'oden',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'officecleaning',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'officeequipment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'oilchange',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'okinawan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'okonomiyaki',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'oliveoil',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'oncologist',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'onigiri',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'opensandwiches',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'opera',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'opthamalogists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'opticians',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'optometrists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'oralsurgeons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'organdonorservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'oriental',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'orthodontists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'orthopedists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'orthotics',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'osteopathicphysicians',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'osteopaths',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'otologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ottomancuisine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'outdoorfurniture',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'outdoorgear',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'outdoormovies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'outdoorpowerequipmentservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'oxygenbars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'oyakodon',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pachinko',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'paddleboarding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'painmanagement',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'paintandsip',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'paintball',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'paintstores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'paintyourownpottery',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pakistani',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'palatine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'panasian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pancakes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'panzerotti',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'paragliding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'parasailing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'parentingclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'parklets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'parma',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'partybikerentals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'partybusrentals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'partyequipmentrentals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'passportvisaservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pastashops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'patentlaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pathologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'patiocoverings',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pawn',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'paydayloans',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pediatric_dentists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pediatricians',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pedicabs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pekinese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pensions',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'perfume',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'periodontists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'persian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'personal_injury',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'personalassistants',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'personalcare',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'personalchefs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'peruvian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pest_control',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pet_sitting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pet_training',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'petboarding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'petbreeders',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'petcremation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pethospice',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'petinsurance',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'petphotography',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pettingzoos',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pettransport',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'petwasteremoval',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pfcomercial',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'phlebologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'photoboothrentals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'photoclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'photographers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'photographystores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'piadina',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pianobars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pianoservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pianostores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pickleball',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pickyourown',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'piemonte',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'piercing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pierogis',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pilates',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pita',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pizza',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'placentaencapsulation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'planetarium',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'plasticsurgeons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'playsets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'plus_size_fashion',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'podiatrists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'poke',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'poledancingclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'polish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'polynesian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'poolbilliards',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'poolcleaners',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'poolhalls',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'poolservice',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'popcorn',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'popuprestaurants',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'popupshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'portabletoiletservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'portuguese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'postpartumcare',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'potatoes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'poutineries',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'powdercoating',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'prenatal',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pressurewashers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pretzels',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'preventivemedicine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'printmedia',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'privatejetcharter',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'privateschools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'privatetutors',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'processservers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'proctologist',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'productdesign',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'propertymgmt',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'props',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'prosthetics',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'prosthodontists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'provencal',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'psychiatrists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'psychic_astrology',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'psychicmediums',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'psychics',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'psychoanalysts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'psychologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'psychotechnicaltests',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'psychotherapists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pubfood',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'publicadjusters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'publicart',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'publicmarkets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'publicplazas',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'publicrelations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'publictransport',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pubs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pueblan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'puertorican',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pulmonologist',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pulquerias',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'pumpkinpatches',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'qigong',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'races',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'racetracks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'racingexperience',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'radiologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'radiostations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'rafting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ramen',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ranches',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'raw_food',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'realestatelawyers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'record_labels',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'recording_studios',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'refinishing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'reflexology',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'registrationservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'registry_office',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'rehabilitation_center',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'religiousorgs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'religiousschools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'reproductivehealthservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'reptileshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'residences',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'resorts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'reststops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'retinaspecialists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'retirement_homes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'reunion',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'rhematologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'rhinelandian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ribatejo',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'riceshop',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'roadsideassist',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'robatayaki',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'rock_climbing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'rodeo',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'rodizios',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'rolfing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'roman',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'romanian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'roofinspectors',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'rotisserie_chicken',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'rugs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'rumanian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'russian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ryokan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'safestores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'safetyequipment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sakebars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'salad',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'salumerie',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'salvadoran',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sambaschools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sandblasting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sandwiches',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sardinian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'saunainstallation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'saunas',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'scandinavian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'scandinaviandesign',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'scavengerhunts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'schnitzel',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'scooterrentals',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'scootertours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'scottish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'screen_printing_tshirt_printing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'screenprinting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'scuba',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'seafood',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'seafoodmarkets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'seasonaldecorservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'selfdefenseclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'senegalese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'seniorcenters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'septicservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'serbocroatian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'service_stations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sessionphotography',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sewingalterations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sextherapists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'shanghainese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sharedofficespaces',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sharedtaxis',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'shavedice',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'shavedsnow',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'shedsandoutdoorstorage',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'shoes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'shredding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'shrines',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'shutters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sicilian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'signature_cuisine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'signmaking',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'singaporean',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'skate_parks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'skateshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'skatingrinks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'skiing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'skillednursing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'skincare',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'skiresorts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'skischools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'skishops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'skydiving',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sledding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sleepspecialists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sleepwear',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'slovakian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'smog_check_stations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'smokehouse',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'smokingareas',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'snorkeling',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'snowremoval',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'snuggleservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'soba',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'social_clubs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'socialsecuritylaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'softwaredevelopment',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'solarinstallation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'solarpanelcleaning',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'somali',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sommelierservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sophrologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'soulfood',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'soup',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'southafrican',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'southern',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'souvenirs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'spanish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'speakeasies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'specialbikes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'specialed',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'speech_therapists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'speechtraining',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'spermclinic',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'spinclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'spinesurgeons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'spiritism',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sport_equipment_hire',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sportsbars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sportsbetting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sportsmed',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sportspsychologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sportswear',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'spraytanning',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'squash',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'srilankan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'stationery',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'steak',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'stereo_installation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'stockings',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'stonemasons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'streetart',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'stripclubs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'stripteasedancers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'structuralengineers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'stucco',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'studiotaping',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sud_ouest',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sugaring',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sugarshacks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sukiyaki',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sunglasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'supperclubs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'suppliesrestaurant',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'surfing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'surflifesaving',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'surfschools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'surfshop',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'surgeons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'sushi',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'swabian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'swedish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'swimminglessons',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'swimmingpools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'swimwear',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'swissfood',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'syrian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'szechuan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tabac',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tabernas',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tablaoflamenco',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tabletopgames',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tableware',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tacos',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'taekwondo',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'taichi',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'taiwanese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'taiyaki',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'takoyaki',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'talentagencies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tamales',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tanning',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tanningbeds',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'taoisttemples',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tapas',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tapasmallplates',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tastingclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tattoo',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tattooremoval',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tavolacalda',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'taxidermy',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'taxis',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'taxlaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'taxoffice',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tcm',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'teachersupplies',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'teambuilding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'telecommunications',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'televisionstations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tempura',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tenantlaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tennis',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'teochew',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'teppanyaki',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'testprep',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tex-mex',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'thai',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'theater',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'themedcafes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'threadingservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'thrift_stores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tickets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ticketsales',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tikibars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tiling',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tires',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'titleloans',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tobaccoshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tofu',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tonkatsu',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'torshi',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tortillas',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'towing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'towncarservice',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'townhall',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'toxicologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tradamerican',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tradclothing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tradefairs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'traditional_swedish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'trafficschools',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'trafficticketinglaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'trains',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'trampoline',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'translationservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'transmissionrepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'transport',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tras_os_montes',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'trattorie',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'travelagents',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'travelservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'trinidadian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'triviahosts',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'trophyshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tropicalmedicine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tubing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tuina',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'turkish',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'turkishravioli',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'tuscan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'udon',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'ukrainian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'unagi',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'underseamedicine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'uniforms',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'university_housing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'unofficialyelpevents',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'urologists',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'usedbooks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'utilities',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'uzbek',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'valetservices',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vapeshops',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vascularmedicine',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vegan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vegetarian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vehicleshipping',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vehiclewraps',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'venetian',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'venezuelan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'venison',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'venues',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vermouthbars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'veteransorganizations',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'videoandgames',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'videogamestores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'videographers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vietnamese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vintage',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vinyl_records',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vinylsiding',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'virtualrealitycenters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'visitorcenters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vitaminssupplements',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vocalcoach',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'vocation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'volleyball',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'waffles',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'walkinclinics',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'walkingtours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'wallpapering',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'watch_repair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'watches',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'waterdelivery',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'waterheaterinstallrepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'waterparks',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'waterproofing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'waterstores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'watersuppliers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'watertaxis',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'waxing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'web_design',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'weddingchappels',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'weightlosscenters',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'welldrilling',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'westernjapanese',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'whalewatchingtours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'wheelrimrepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'whiskeybars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'wholesale_stores',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'wholesalers',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'wigs',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'wildlifehunting',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'willstrustsprobates',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'windowsinstallation',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'windowwashing',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'windshieldinstallrepair',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'wine_bars',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'winetasteclasses',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'winetastingroom',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'winetours',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'wok',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'womenscloth',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'workerscomplaw',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'wraps',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'xmasmarkets',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'yakiniku',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'yakitori',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'yelpevents',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'yoga',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'youth_club',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'yucatan',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'yugoslav',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'zapiekanka',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'zipline',
        icon: 'generic',
    },
    {
        parentCategory: '',
        category: 'zorbing',
        icon: 'generic',
    },
];

const colors = [
    {
        parentCategory: 'active',
        color: '#885A98',
    },
    {
        parentCategory: 'arts',
        color: '#AF91BA',
    },
    {
        parentCategory: 'auto',
        color: '#65C6F6',
    },
    {
        parentCategory: 'beautysvc',
        color: '#B34477',
    },
    {
        parentCategory: 'education',
        color: '#BBCC30',
    },
    {
        parentCategory: 'eventservices',
        color: '#C35A36',
    },
    {
        parentCategory: 'financialservices',
        color: '#0FB6C6',
    },
    {
        parentCategory: 'food',
        color: '#59A575',
    },
    {
        parentCategory: 'health',
        color: '#F18289',
    },
    {
        parentCategory: 'homeservices',
        color: '#8E9DDA',
    },
    {
        parentCategory: 'hotelstravel',
        color: '#E0B4C8',
    },
    {
        parentCategory: 'localflavor',
        color: '#CC82A4',
    },
    {
        parentCategory: 'localservices',
        color: '#607D8B',
    },
    {
        parentCategory: 'massmedia',
        color: '#FCD621',
    },
    {
        parentCategory: 'nightlife',
        color: '#2E2E2E',
    },
    {
        parentCategory: 'pets',
        color: '#885A4D',
    },
    {
        parentCategory: 'professional',
        color: '#695EBC',
    },
    {
        parentCategory: 'publicservicesgovt',
        color: '#93AE8A',
    },
    {
        parentCategory: 'realestate',
        color: '#EA444E',
    },
    {
        parentCategory: 'religiousorgs',
        color: '#7F7F7F',
    },
    {
        parentCategory: 'restaurants',
        color: '#F29447',
    },
    {
        parentCategory: 'shopping',
        color: '#5795E9',
    },
];

const configuration = {
    amenities: Object.entries(amenitiesData),
    iconsByCategory: keyBy(icons, 'category'),
    colors: keyBy(colors, 'parentCategory'),
    iconUrls: values(keyBy(icons, 'icon')).map(function (item) {
        return item.icon + '.svg';
    }),
};
export default configuration;
