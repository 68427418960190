import { forwardRef, Ref } from 'react';
import { LinkProps, useHref, useLinkClickHandler } from 'react-router-dom';
import { Typography } from '@jll/react-ui-components';

const TypographyLink = (props: LinkProps, ref: Ref<HTMLElement>) => {
    const href = useHref(props.to);
    const handleClick = useLinkClickHandler(props.to, {
        replace: props.replace,
        state: props.state,
        target: props.target,
    });

    return (
        <Typography.Link
            ref={ref}
            target={props.target}
            href={href}
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                props.onClick?.(event);
                if (!event.defaultPrevented) handleClick(event);
            }}
        >
            {props.children}
        </Typography.Link>
    );
};

export default forwardRef(TypographyLink);
