import { ReactNode, useEffect, useState } from 'react';
import { Divider, Form, Input, Modal, Row, Typography } from '@jll/react-ui-components';

import {
    getDefaultPresentation,
    PresentationSummary,
    searchPresentations,
} from 'api/presentationApi';
import GridItem from 'components/GridItem';
import MarketSelect, { MarketSummaryOption } from '../MarketSelect';
import PresentationImage from './PresentationImage';

import gridStyles from './PresentationGrid.module.scss';

import NewPresentationIcon from 'icons/custom/newPresentation.svg';

async function fetchDefaultPresentation(marketId: number) {
    return await getDefaultPresentation(marketId);
}

async function fetchLastUsedPresentation(marketId: number) {
    const result = await searchPresentations({
        limit: 1,
        marketIds: marketId.toString(),
        orderBy: 'AccessedDate desc',
    });

    const presentationSummaries = result.presentationSummaries;
    if (presentationSummaries.length === 0) return null;

    return result.presentationSummaries[0];
}

function createNewPresentation(
    presentationName: string,
    marketId?: number,
    presentationId?: number,
    cleanSlides?: boolean
) {
    const queryParameters = new URLSearchParams({
        PresentationName: presentationName,
    });

    if (presentationId) {
        queryParameters.append('P', presentationId.toString());
    } else if (marketId) {
        queryParameters.append('MarketId', marketId.toString());
    }

    if (cleanSlides) {
        queryParameters.append('clean', cleanSlides.toString());
    }

    window.location.href = `/index.html?${queryParameters.toString()}`;
}

function CircularNumber({ children }: { children: ReactNode }) {
    return (
        <span
            style={{
                marginRight: 16,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 40,
                height: 40,
                backgroundColor: ' #F2F2F2',
                borderRadius: 100,
            }}
        >
            {children}
        </span>
    );
}

interface PresentationContent {
    id: string;
    presentationId?: number | undefined;
    title: string;
    subTitle: string;
    cleanSlides: boolean;
    image: ReactNode;
}

const _newPresentation = {
    id: 'new-presentation-window__from-scratch',
    title: 'Start from scratch',
    subTitle: 'Open blank presentation',
    cleanSlides: true,
    image: <NewPresentationIcon color='#C7CACE' width={'50%'} height={'50%'} />,
};

interface NewPresentationProps {
    visible: boolean;
    onClose: () => void;
}

export default function NewPresentationModal({ visible, onClose }: NewPresentationProps) {
    const [form] = Form.useForm();
    const [presentationName, setPresentationName] = useState('');
    const [selectedMarket, setSelectedMarket] = useState<MarketSummaryOption>();
    const [defaultPresentation, setDefaultPresentation] = useState<PresentationSummary | null>();
    const [lastUsedPresentation, setLastUsedPresentation] = useState<PresentationSummary | null>();
    const [selectedContent, setSelectedContent] = useState<PresentationContent>(_newPresentation);

    function handleFormSubmit() {
        const { presentationId, cleanSlides } = selectedContent;
        createNewPresentation(presentationName, selectedMarket?.value, presentationId, cleanSlides);
    }

    function clearState() {
        setSelectedMarket(undefined);
        setPresentationName('');
        setLastUsedPresentation(null);
        setDefaultPresentation(null);
        setSelectedContent(_newPresentation);
        form.resetFields();
    }

    useEffect(() => {
        async function updatePresentations() {
            const marketId = selectedMarket?.value;
            if (!marketId) {
                setDefaultPresentation(null);
                setLastUsedPresentation(null);
                return;
            }

            const [lastUsedPresentation, defaultPresentation] = await Promise.all([
                fetchLastUsedPresentation(marketId),
                fetchDefaultPresentation(marketId),
            ]);

            setDefaultPresentation(defaultPresentation);
            setLastUsedPresentation(lastUsedPresentation);
            setSelectedContent(_newPresentation);
        }
        updatePresentations();
    }, [selectedMarket]);

    const content: PresentationContent[] = [_newPresentation];

    if (defaultPresentation) {
        content.push({
            id: 'new-presentation-window__default',
            title: defaultPresentation.presentationName,
            subTitle: 'Blackbird official',
            cleanSlides: false,
            image: (
                <PresentationImage
                    presentationId={defaultPresentation.id}
                    modifiedDate={defaultPresentation.modifiedDate}
                />
            ),
        });
    }

    if (lastUsedPresentation) {
        content.push({
            id: 'new-presentation-window__last-used',
            presentationId: lastUsedPresentation.id,
            title: lastUsedPresentation.presentationName,
            subTitle: 'Use your last presentation',
            cleanSlides: true,
            image: (
                <PresentationImage
                    presentationId={lastUsedPresentation.id}
                    modifiedDate={lastUsedPresentation.modifiedDate}
                />
            ),
        });
    }

    return (
        <Modal
            title='Create New Presentation'
            centered
            open={visible}
            onOk={form.submit}
            okText='Create Presentation'
            okButtonProps={{ id: 'new-presentation-window__confirm-button' }}
            cancelButtonProps={{ id: 'new-presentation-window__cancel-button' }}
            width={'95vw'}
            styles={{ body: { height: '80vh' } }}
            afterClose={clearState}
            onCancel={onClose}
        >
            <div style={{ height: '100%', display: 'grid', gridTemplateColumns: '.75fr auto 1fr' }}>
                <div>
                    <Row style={{ alignItems: 'baseline', marginBottom: 40 }}>
                        <CircularNumber>1</CircularNumber>
                        <Typography.Title level={5}>About Your Presentation</Typography.Title>
                    </Row>
                    <Form form={form} layout='vertical' onFinish={handleFormSubmit}>
                        <Form.Item
                            name='Presentation name'
                            label='Presentation name'
                            rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                        >
                            <Input
                                id='new-presentation-window__presentation-name-input'
                                value={presentationName}
                                onChange={(e) => setPresentationName(e.currentTarget.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            name='Presentation market'
                            label='Presentation market'
                            rules={[{ required: true }]}
                        >
                            <MarketSelect
                                id='new-presentation-window__market-select'
                                showSearch={true}
                                onChange={(value) =>
                                    setSelectedMarket(value as MarketSummaryOption)
                                }
                                style={{ width: '100%' }}
                                value={selectedMarket}
                            />
                        </Form.Item>
                    </Form>
                </div>

                <Divider
                    type='vertical'
                    style={{ margin: '-24px 24px 24px 24px', height: 'calc(48px + 100%)' }}
                />
                <div>
                    <Row style={{ alignItems: 'baseline', marginBottom: 32 }}>
                        <CircularNumber>2</CircularNumber>
                        <Typography.Title level={5}>
                            What type of content do you want to include?
                        </Typography.Title>
                    </Row>
                    <div className={gridStyles.grid}>
                        {content.map((item) => (
                            <GridItem
                                key={item.id}
                                id={item.id}
                                selected={selectedContent.id === item.id}
                                onClick={() => {
                                    setSelectedContent(item);
                                }}
                                image={item.image}
                                title={item.title}
                                subTitle={item.subTitle}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
