import { ReactElement, useEffect, useState } from 'react';
import { Spin } from '@jll/react-ui-components';
import format from 'date-fns/format';

import { PresentationSummary, searchPresentations } from 'api/presentationApi';
import GridItem from 'components/GridItem';
import { getPresentationUrl } from 'utils/apiClient/urlUtil';
import { emailToDisplayName } from 'utils/emailUtils';
import WelcomeSectionHeader from '../welcome/WelcomeSectionHeader';
import EmptyPresentationList from './EmptyPresentationList';
import PresentationImage from './PresentationImage';

import styles from './PresentationGrid.module.scss';

export default function RecentlyViewedPresentations(): ReactElement {
    const [presentations, setPresentations] = useState<PresentationSummary[] | undefined>();

    const title = 'Recently Viewed';
    const linkTo = 'presentations';
    const linkTitle = 'Go to My Presentations';

    useEffect(() => {
        const getPresentations = async () => {
            try {
                const result = await searchPresentations({
                    orderBy: 'AccessedDate desc',
                    limit: 9,
                });

                setPresentations(result?.presentationSummaries);
            } catch (error) {
                console.error(error);
                // TODO: Show error message.
            }
        };

        getPresentations();
    }, []);

    return (
        <div style={{ padding: '32px', minWidth: '355px' }}>
            <WelcomeSectionHeader
                title={title}
                titleSize={20}
                linkTo={linkTo}
                linkId='dashboard__recently-viewed-link'
                linkTitle={linkTitle}
            />
            {getPresentationsTemplates(presentations)}
        </div>
    );
}

function getPresentationsTemplates(presentations?: PresentationSummary[]): JSX.Element {
    if (!presentations) {
        return <Spin style={{ display: 'block', margin: '48px auto' }} />;
    }

    if (presentations.length) {
        const presentationsTemplate = presentations.map((presentation: PresentationSummary) => {
            const description = (
                <>
                    {emailToDisplayName(presentation.createdBy)} <span> &bull; </span>{' '}
                    {format(new Date(presentation.createdDate), 'MM/dd/yyyy')}
                </>
            );
            return (
                <a
                    className='recently-viewed-presentation-link'
                    key={presentation.id}
                    href={getPresentationUrl(presentation.id, presentation.clientVersion)}
                >
                    <GridItem
                        image={
                            <PresentationImage
                                presentationId={presentation.id}
                                modifiedDate={presentation.modifiedDate}
                            />
                        }
                        subTitle={presentation.marketName}
                        title={presentation.presentationName}
                        description={description}
                    />
                </a>
            );
        });

        return <div className={styles.grid}>{presentationsTemplate}</div>;
    }

    return (
        <EmptyPresentationList>
            <p>A list of files you recently viewed will show here.</p>
        </EmptyPresentationList>
    );
}
