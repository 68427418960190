export const systemQuery = `
If a user is asking for help or wondering what you can do, return the type 'whatcanido'.
If a user is requesting to view buildings, properties, availabilities, or leases by search criteria return 'search'. 
If a user is requesting to highlight a building return 'highlightsets'.
If a user mentions anything about adding or seeing content or layers, return the type 'maplayers'.
If a user mentions changing the map view such as flying to a location or showing a location and doesn't fit any of the above rules return the type 'view'. e.g. Show me this building or location, fly me to this location.
If a user mentions, ask or request development pipeline, development pipeline layer, dev pipeline layer or anything similar, return 'dev-pipeline'
`;

export enum SystemType {
    Unsupported = 'unsupported',
    WhatCanIDO = 'whatcanido',
    Search = 'search',
    HighlightSets = 'highlightsets',
    MapLayers = 'maplayers',
    View = 'view',
    DevPipeline = 'dev-pipeline',
}

const systemTypes = [
    SystemType.Unsupported,
    SystemType.WhatCanIDO,
    SystemType.Search,
    SystemType.HighlightSets,
    SystemType.MapLayers,
    SystemType.View,
    SystemType.DevPipeline,
];

export const systemTypeFunctionSchema = {
    name: 'getSystemType',
    description: 'Get the system type from the context of the query message',
    parameters: {
        type: 'object',
        properties: {
            systemType: {
                type: 'string',
                description: 'The matched system type. Use all lowercase for the system type.',
                enum: systemTypes,
            },
        },
        required: ['systemType'],
    },
};
