import { useMemo } from 'react';
import SceneView from '@arcgis/core/views/SceneView';
import { App, Menu } from '@jll/react-ui-components';

import { identifyLibraryItemKey } from 'helpers/libraryLayerHelper';
import { useMap } from 'hooks/MapProvider';
import { AppThunk } from 'store';
import { startMatch } from 'store/csvHighlightMatchSlice';
import { selectSelectedMovePin, startMovingPin } from 'store/movePinSlice';
import { RightPanelKeys, setActiveRightPanel } from 'store/rightPanelSlice';
import { useAppDispatch } from 'types/hooks';
import { ContextMenuItem } from 'types/Layers/ContextMenu';
import { nanoid } from 'utils/idUtils';

interface MoveOsmHighlightPinMenuProps {
    view: SceneView | null;
    libraryItemKey?: string;
}

function movePin(message: ReturnType<typeof App['useApp']>['message']): AppThunk<void> {
    return (dispatch, getState) => {
        const state = getState();
        const selectedMovePin = selectSelectedMovePin(state);
        if (!selectedMovePin) {
            return;
        }

        const loadingMessageKey = nanoid();

        const pinId =
            selectedMovePin.pinReference.type === 'highlight-pin-reference'
                ? selectedMovePin.pinReference.displayName
                : selectedMovePin.pinReference.index;
        message.loading(
            {
                content: `Pin from Highlight set entry ${pinId} Selected. Now click in the place you want to move the pin.`,
                key: loadingMessageKey,
            },
            0
        );
        dispatch(
            startMovingPin({
                loadingMessageKey,
            })
        );
    };
}

export default function OsmHighlightPinMenu({
    view: _view,
    libraryItemKey: _libraryItemKey,
}: MoveOsmHighlightPinMenuProps): JSX.Element {
    const { message } = App.useApp();
    const dispatch = useAppDispatch();
    const { hideMapContextMenu } = useMap();
    const libraryItemKeyLength = _libraryItemKey && identifyLibraryItemKey(_libraryItemKey);

    const menuItems = useMemo(
        () => [
            ContextMenuItem({
                label: 'Move Pin',
                key: 'movePin',
                onClick: () => {
                    hideMapContextMenu();
                    dispatch(movePin(message));
                },
            }),
            ...(libraryItemKeyLength === 4
                ? [
                      ContextMenuItem({
                          label: 'Review Match',
                          key: 'reviewMatch',
                          onClick: () => {
                              if (_libraryItemKey) {
                                  dispatch(startMatch(_libraryItemKey));
                                  dispatch(
                                      setActiveRightPanel(RightPanelKeys.TableToHighlightMatch)
                                  );
                                  hideMapContextMenu();
                              }
                          },
                      }),
                  ]
                : []),
        ],
        [libraryItemKeyLength, hideMapContextMenu, dispatch, message, _libraryItemKey]
    );

    return <Menu selectable={false} style={{ width: 180, borderRadius: 4 }} items={menuItems} />;
}
