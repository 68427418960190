import { useCallback, useEffect, useState } from 'react';
import { Form, Select } from '@jll/react-ui-components';

import { MappedMarket } from 'api/marketApi';
import { getPresentationsByMarketCodes } from 'api/presentationApi';
import { marketComponents } from 'constants/markets.constants';

const MarketSphereMarketEditorFormItems = ({
    defaultPresentationId,
    marketSphereMarkets,
    marketSphereMarketsOptions,
}: {
    defaultPresentationId: number;
    marketSphereMarkets: string[];
    marketSphereMarketsOptions: Record<string, Record<string, string | MappedMarket>>;
}) => {
    const [relatedPresentations, setRelatedPresentations] = useState<Record<number, string>>({});

    const loadRelatedMarkets = useCallback(
        async function loadRelatedMarkets() {
            const codes = marketSphereMarkets.map((m: string) => Number(m.split('-')[0]));
            const relatedMarkets =
                codes.length === 0 ? [] : await getPresentationsByMarketCodes(codes);

            setRelatedPresentations(relatedMarkets);
        },
        [marketSphereMarkets]
    );

    useEffect(() => {
        if (!marketSphereMarkets?.length) {
            setRelatedPresentations({});
            return;
        }

        loadRelatedMarkets();
    }, [loadRelatedMarkets, marketSphereMarkets]);

    return (
        <>
            <Form.Item
                label='MarketSphere Markets'
                name='marketSphereMarkets'
                rules={[
                    {
                        required: true,
                        message: 'Please select a market sphere market',
                    },
                ]}
            >
                <Select
                    mode='multiple'
                    allowClear
                    optionFilterProp='label'
                    options={Object.values(marketSphereMarketsOptions)}
                />
            </Form.Item>
            <Form.Item
                label='Default Presentation'
                valuePropName='label'
                normalize={(value) => {
                    return Number(value);
                }}
                name='defaultPresentationId'
            >
                <Select
                    placeholder='None'
                    allowClear
                    showSearch
                    optionFilterProp='label'
                    value={
                        defaultPresentationId ? relatedPresentations[defaultPresentationId] : null
                    }
                    options={Object.entries(relatedPresentations).map(([id, name]) => ({
                        key: id,
                        value: id,
                        label: name,
                    }))}
                />
            </Form.Item>
            <Form.Item
                label='MarketSphere Components'
                name='marketSphereComponents'
                rules={[
                    {
                        required: true,
                        message: 'Please select a market sphere component',
                    },
                ]}
            >
                <Select
                    mode='multiple'
                    allowClear
                    options={marketComponents}
                    fieldNames={{ label: 'name', value: 'id' }}
                />
            </Form.Item>
        </>
    );
};

export default MarketSphereMarketEditorFormItems;
