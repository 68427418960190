import { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    message,
    Modal,
    Row,
    Select,
    Tag,
    Typography,
} from '@jll/react-ui-components';

import { shareWithExternalClient } from 'api/clientShareApi';
import {
    closePresentationExternalInviteModal,
    closePresentationInternalInviteModal,
    selectPresentationExternalInviteModalOpen,
} from 'store/presentationShareSlice';
import { selectCurrentUser } from 'store/userSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';

const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

/** Adapted from CustomTagProps in BasicSelect.d.ts */
type CustomTagProps = {
    label: React.ReactNode;
    value: string;
    disabled: boolean;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    closable: boolean;
};
/**
 * A Custom renderer for the email tags in the email selector.
 *
 * This component currently does not handle the disabled state as
 * in the current version of the code this situation does not happen.
 *
 * @param props The custom tag props passed in from the Ant Select component
 * @returns The JSX Element for the component
 */
function EmailTag(props: CustomTagProps): JSX.Element {
    const color = EMAIL_REGEX.test(props.value) ? undefined : 'red';
    return (
        <Tag
            onClose={props.onClose}
            closable={props.closable}
            color={color}
            style={{ fontSize: 14 }}
        >
            {props.label}
        </Tag>
    );
}

export function ExternalShareInviteModal({ presentationId }: { presentationId: number }) {
    const dispatch = useAppDispatch();
    const open = useAppSelector(selectPresentationExternalInviteModalOpen);
    const user = useAppSelector(selectCurrentUser);
    const [usersAdded, setUsersAdded] = useState<string[]>([]);
    const [showError, setShowError] = useState<boolean>(true);

    useEffect(() => {
        setShowError(false);
    }, [usersAdded, open]);

    const goBack = () => {
        dispatch(closePresentationExternalInviteModal());
    };

    const handleShare = async () => {
        if (usersAdded.length === 0) {
            setShowError(true);
            return;
        }
        if (usersAdded.filter((u) => !EMAIL_REGEX.test(u)).length > 0) {
            setShowError(true);
            return;
        }
        for (let i = 0; i < usersAdded.length; i++) {
            await shareWithExternalClient(presentationId, usersAdded[i], user.displayName, '');
            message.success(`Email sent to ${usersAdded[i]}`);
        }
        dispatch(closePresentationInternalInviteModal());
    };

    const handleChange = (value: string[]) => {
        setUsersAdded(value);
    };

    return (
        <Modal
            title={
                <>
                    <Button size='large' type='text' onClick={goBack}>
                        <ArrowLeftOutlined />
                    </Button>
                    <Typography.Text
                        style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                        }}
                    >
                        Invite External Users
                    </Typography.Text>
                </>
            }
            open={open}
            width='600px'
            centered
            footer={null}
            onCancel={goBack}
        >
            <Row>
                <Col span={24}>
                    <Typography.Text italic style={{ fontSize: '14px' }}>
                        Clicking Share will email a one-time Blackbird link to the clients you have
                        added below. The client will be able to refresh their link. To enter
                        multiple email address use comma or semi-colon to separate emails.
                    </Typography.Text>
                </Col>
            </Row>
            <Row gutter={8} style={{ marginTop: 20, marginBottom: 20 }}>
                <Col span={24}>
                    <Select
                        placeholder='Enter email addresses'
                        onChange={handleChange}
                        mode='tags'
                        allowClear
                        style={{ width: '100%' }}
                        notFoundContent={null}
                        filterOption={false}
                        tokenSeparators={[',', ';']}
                        suffixIcon={null}
                        open={false}
                        tagRender={EmailTag}
                    />
                </Col>
            </Row>
            {showError && (
                <Row>
                    <Col span={24}>
                        <Typography.Text type='danger'>
                            Please enter a valid email addresses
                        </Typography.Text>
                    </Col>
                </Row>
            )}
            <Row justify='end' style={{ marginTop: 20 }}>
                <Col>
                    <Button type='primary' onClick={handleShare}>
                        Share
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}
