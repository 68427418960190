import { useCallback, useEffect } from 'react';
import { App } from '@jll/react-ui-components';

import { serializeCameraSettings } from 'helpers/cameraHelper';
import { getAllKeysIfLegendEnabled } from 'helpers/libraryLayerHelper';
import { takeScreenShot } from 'helpers/slideHelper';
import { selectBasemapId } from 'store/basemapSlice';
import { selectDaylightView } from 'store/daylightWidgetSlice';
import { selectFeatures, selectLegendState } from 'store/featuresSlice';
import { selectCheckedKeys, selectLibraryItems } from 'store/libraryItemSlice';
import { addSlide, setActiveSlide, setFocusedSlide, updateSlide } from 'store/slideSlice';
import { selectStreetView } from 'store/streetMapViewSlice';
import { selectWeatherWidgetSettings } from 'store/weatherWidgetSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import { getCameraSettings } from 'utils/esri/cameraInstanceUtils';
import { nanoid } from 'utils/idUtils';
import { Slide, SlideImageType } from '../SlideTypes';

export default function useSlideCapture() {
    const dispatch = useAppDispatch();
    const checkedKeys = useAppSelector<string[]>(selectCheckedKeys);
    const currentBasemap = useAppSelector(selectBasemapId);
    const legendState = useAppSelector(selectLegendState);
    const libraryItems = useAppSelector(selectLibraryItems);
    const streetViewState = useAppSelector(selectStreetView);
    const daylightViewState = useAppSelector(selectDaylightView);
    const { state: weatherWidgetState } = useAppSelector(selectWeatherWidgetSettings);
    const baseFeatures = useAppSelector(selectFeatures);
    const { message } = App.useApp();

    const getSlideState = useCallback(() => {
        const legendEnabledKeys = getAllKeysIfLegendEnabled(libraryItems);
        return {
            camera: serializeCameraSettings(getCameraSettings()),
            layerIds: checkedKeys,
            mapFeatures: baseFeatures,
            legend: {
                ...legendState,
                openKeys: legendEnabledKeys,
            },
            basemap: currentBasemap,
            streetView: streetViewState,
            daylightView: daylightViewState,
            weather: weatherWidgetState,
        };
    }, [
        baseFeatures,
        checkedKeys,
        currentBasemap,
        daylightViewState,
        legendState,
        libraryItems,
        streetViewState,
        weatherWidgetState,
    ]);

    useEffect(() => {
        getSlideState();
    }, [getSlideState]);

    return {
        captureSlide: async () => {
            const imageType = SlideImageType.Default;
            const state = getSlideState();
            const slide = {
                slideItemType: 'Slide',
                key: nanoid(),
                state,
                imageType,
                cloudinaryImageId: 'capturing',
            } as Slide;

            dispatch(addSlide(slide));
            dispatch(setFocusedSlide({ key: slide.key, scrollTo: true, renaming: true }));
            dispatch(setActiveSlide(slide));

            if (imageType === SlideImageType.Default) {
                const imageId = await takeScreenShot();
                if (imageId)
                    dispatch(
                        updateSlide({ key: slide.key, updates: { cloudinaryImageId: imageId } })
                    );
            }
        },

        refreshSlide: async (slide: Slide) => {
            const slideState = getSlideState();
            let updatedCloudinaryImageId = slide.cloudinaryImageId;
            if (slide.imageType === SlideImageType.Default) {
                const imageId = await takeScreenShot();
                if (imageId) updatedCloudinaryImageId = imageId;
            }

            dispatch(
                updateSlide({
                    key: slide.key,
                    updates: {
                        state: { ...slideState },
                        cloudinaryImageId: updatedCloudinaryImageId,
                    },
                })
            );
            message.success('Slide is updated');
        },
    };
}
