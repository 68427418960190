import { ReactElement, ReactNode } from 'react';
import missingFilesIcon from 'images/missingFiles.svg?url';

import styles from './EmptyPresentationList.module.scss';

export default function EmptyPresentationList({ children }: { children: ReactNode }): ReactElement {
    return (
        <div className={styles.emptyListContainer}>
            <img src={missingFilesIcon} />
            {children}
        </div>
    );
}
