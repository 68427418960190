import { useMemo } from 'react';
import SceneView from '@arcgis/core/views/SceneView';
import { Menu } from '@jll/react-ui-components';

import { useMap } from 'hooks/MapProvider';
import { confirmMatch, selectCsvMatchState, viewMatch } from 'store/csvHighlightMatchSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import { ContextMenuItem } from 'types/Layers/ContextMenu';

interface MatchCsvOsmHighlightMenuProps {
    view: SceneView | null;
}
export default function MatchCsvOsmHighlightMenu({
    view: _view,
}: MatchCsvOsmHighlightMenuProps): JSX.Element {
    const dispatch = useAppDispatch();
    const matchState = useAppSelector(selectCsvMatchState);
    const { hideMapContextMenu } = useMap();

    const menuItems = useMemo(
        () => [
            ContextMenuItem({
                label: 'Confirm Match',
                key: 'confirm-match',
                onClick: () => {
                    if (matchState.stateName !== 'CONFIRM_MATCH') {
                        return;
                    }
                    dispatch(
                        confirmMatch({
                            csvItemKey: matchState.csvItemKey,
                            osmId: matchState.candidateMatchOsmId,
                        })
                    );
                    dispatch(viewMatch(matchState.csvItemKey));
                    hideMapContextMenu();
                },
            }),
        ],
        [matchState, hideMapContextMenu, dispatch]
    );

    return <Menu selectable={false} style={{ width: 180, borderRadius: 4 }} items={menuItems} />;
}
