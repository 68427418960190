import Basemap from '@arcgis/core/Basemap';
import TileLayer from '@arcgis/core/layers/TileLayer';
import WebTileLayer from '@arcgis/core/layers/WebTileLayer';

import aerialBasemapImageUrl from 'images/basemaps/aerial.webp';
import darkBasemapImageUrl from 'images/basemaps/dark.webp';
import nationalGeographicBasemapImageUrl from 'images/basemaps/geographic.webp';
import lightBasemapImageUrl from 'images/basemaps/light.webp';
import nearmapBasemapImageUrl from 'images/basemaps/nearmap.webp';
import novaBasemapImageUrl from 'images/basemaps/nova.webp';
import osmBasemapImageUrl from 'images/basemaps/osm.webp';
import topographicBasemapImageUrl from 'images/basemaps/topographic.webp';

const ARCGIS_REST_URL = 'https://www.arcgis.com';
const TRAFFIC_REST_URL = 'https://traffic.arcgis.com';
const LANDSCAPE1_URL = 'landscape1.arcgis.com';
const LANDSCAPE2_URL = 'landscape2.arcgis.com';
const LANDSCAPE3_URL = 'landscape3.arcgis.com';
const ARCGIS_ONLINE_URL = 'https://services.arcgis.com';
const TILES_SERVER_URL = 'https://tiles.arcgis.com';
const VECTOR_TILE_SERVER_URL = 'https://vectortileservices.arcgis.com/';
const ARCGIS_ONLINE_UTILITY_SERVER = 'https://utility.arcgis.com';

const JLL_BOX = 'https://jll.box.com';

export const MAPIT_URL = 'https://www.arcgis.com';
export const ARCGIS_LOCATOR_SERVICE_URL =
    'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer';
export const PORTAL_USER_URL = `https://www.arcgis.com/sharing/rest/community/users/`;

export const INTERCEPTOR_URLS = [
    MAPIT_URL,
    LANDSCAPE1_URL,
    LANDSCAPE2_URL,
    LANDSCAPE3_URL,
    ARCGIS_ONLINE_URL,
    TILES_SERVER_URL,
    VECTOR_TILE_SERVER_URL,
    ARCGIS_ONLINE_UTILITY_SERVER,
    ARCGIS_REST_URL,
    TRAFFIC_REST_URL,
];

export const BLACKBIRD_POLYGON_SCENE_LAYER_ID = '7cba30d786084ffab3ae8a7534b38afd';
export const MAPIT_TOKEN = 'mapit-token';
export const MAPIT_TOKEN_EXPIRES = 'mapit-token-expires';

export const BLACKBIRD_IMAGE_PROXY = window.STATIC_CONFIG.blackbirdApiUrl + 'ImageProxy';
export const BLACKBIRD_PROXY_URL_PREFIX = [JLL_BOX];

export const ADD_LAYER_MODAL = {
    BLACKBIRD_LIBRARY_TAB_NAME: 'Blackbird Library',
    BLACKBIRD_LIBRARY_SEARCH_PLACEHOLDER: 'Search library',
};

export const BASEMAP_URLS = {
    ARCGIS_ONLINE: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
    MAPBOX: 'https://api.mapbox.com/styles/v1/jllglobal/',
    NEARMAP: 'https://api.nearmap.com/tiles/v3/Vert/{z}/{x}/{y}.img?apikey=',
    WORLD_TOPO_MAP:
        'https://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer',
};

export const OSM_3D_LABEL_SERVICE_URL =
    'https://basemaps3d.arcgis.com/arcgis/rest/services/OpenStreetMap3D_DarkLabels_v1/SceneServer/layers/0';
export const OSM_3D_LABEL_LAYER_ID = 'OSM-3D-LABELS';

export const MAPBOX_STYLES = {
    AERIAL: 'ckhcj5l8v0udx19nok1tlqcr1',
    BASIC: 'ckhcidzh00tnq19noo6mcwr80',
};

export const DEFAULT_BASEMAP = {
    TOPO_VECTOR: 'topo-vector',
    IMAGERY_HYBRID: '86265e5a4bbb4187a59719cf134e0018',
    NATIONAL_GEOGRAPHIC: '4afed5cef9734e3eac2f247071b63869',
    LIGHT: 'de45b9cad20141ebb82fae0da8b3e2c6',
    DARK: 'a343955125bf4002987c400ad6d0346c',
    NOVA: 'd9915f40d8d64098b9e1e2f8515d1c7a',
    OSM: 'c29cfb7875fc4b97b58ba6987c460862',
};

export interface BasemapProps {
    name: string;
    portalId?: string;
    thumbnail: string;
    basemap: Basemap;
}

export const OSM_BUILDINGS_LAYER_ID = 'ca0470dbbddb4db28bad74ed39949e25';
export const TERRAIN_ID = '7029fb60158543ad845c7e1527af11e4';
export const OSM_TREE_LAYER_ID = '33383da8a75f4d24b4b6a0d0532abe6e';
export const OFFICE_SUBMARKETS_LAYER_ID = 'c624aff4028e4a96a0c3d8ebbfeafd7e';
export const INDUSTRIAL_SUBMARKETS_LAYER_ID = 'a846e02f8df940348122e94ddb10bf95';

export const getBasemaps = (nearmapImageryKey: string): BasemapProps[] => [
    {
        name: 'Imagery w/ Labels',
        basemap: new Basemap({
            id: 'Imagery Hybrid',
            portalItem: {
                id: DEFAULT_BASEMAP.IMAGERY_HYBRID,
            },
        }),
        portalId: DEFAULT_BASEMAP.IMAGERY_HYBRID,
        thumbnail: aerialBasemapImageUrl,
    },
    {
        name: 'Imagery',
        basemap: new Basemap({
            id: 'Nearmap',
            baseLayers: [
                new TileLayer({
                    id: DEFAULT_BASEMAP.IMAGERY_HYBRID,
                    url: BASEMAP_URLS.ARCGIS_ONLINE,
                    visible: true,
                }),
                new WebTileLayer({
                    id: 'NEARMAP',
                    urlTemplate: `${BASEMAP_URLS.NEARMAP}${nearmapImageryKey}`,
                    minScale: 24000,
                }),
            ],
        }),
        thumbnail: nearmapBasemapImageUrl,
    },
    {
        name: 'Dark',
        basemap: new Basemap({
            id: 'Dark',
            portalItem: { id: DEFAULT_BASEMAP.DARK },
        }),
        portalId: DEFAULT_BASEMAP.DARK,
        thumbnail: darkBasemapImageUrl,
    },
    {
        name: 'Light',
        basemap: new Basemap({
            id: 'Light',
            portalItem: { id: DEFAULT_BASEMAP.LIGHT },
        }),
        portalId: DEFAULT_BASEMAP.LIGHT,
        thumbnail: lightBasemapImageUrl,
    },
    {
        name: 'Nova',
        basemap: new Basemap({
            id: 'Nova',
            portalItem: { id: DEFAULT_BASEMAP.NOVA },
        }),
        thumbnail: novaBasemapImageUrl,
    },
    {
        name: 'OSM',
        basemap: new Basemap({
            id: 'OSM',
            portalItem: { id: DEFAULT_BASEMAP.OSM },
        }),
        thumbnail: osmBasemapImageUrl,
    },
    {
        name: 'Geographic',
        basemap: new Basemap({
            id: 'National Geographic',
            portalItem: { id: DEFAULT_BASEMAP.NATIONAL_GEOGRAPHIC },
        }),
        thumbnail: nationalGeographicBasemapImageUrl,
    },
    {
        name: 'Topographic',
        basemap: Basemap.fromId(DEFAULT_BASEMAP.TOPO_VECTOR),
        thumbnail: topographicBasemapImageUrl,
    },
];

export const getJLLLogoForBasemap = (basemapId: string) => {
    switch (basemapId) {
        case 'Dark':
        case 'Nova':
            return require('assets/res/images/logos/jll-logo-white.png');
        default:
            return require('assets/res/images/logos/jll-logo-color.png');
    }
};
