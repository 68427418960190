import { Col, Icon, Row } from '@jll/react-ui-components';

import { defaultPinColor } from 'helpers/highlightSetHelper';

import styles from './HighlightSet.module.scss';

interface HighlightSetIconProps {
    highlightSetColor?: string;
}

export const HighlightSetTitleIcon = ({
    highlightSetColor = defaultPinColor,
}: HighlightSetIconProps): JSX.Element => {
    return (
        <>
            <Row wrap={false} align='middle' style={{ gap: '8px' }}>
                <Col
                    className={styles.layerColorIcon}
                    style={{ backgroundColor: highlightSetColor }}
                />
                <Icon
                    name='apartment'
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '20px',
                        width: '20px',
                        fontSize: '14px',
                    }}
                />
            </Row>
        </>
    );
};
