enum LayerTilesStatus {
    Uploaded = 0,
    Queued = 1,
    Processing = 2,
    Generated = 3,
    Failed = 99,
}

export const LAYER_TILES_STATUS_STRINGS: LayerTilesStatusString[] = [
    'Uploaded',
    'Queued',
    'Processing',
    'Generated',
    'Failed',
];

export type LayerTilesStatusString = keyof typeof LayerTilesStatus;

export default LayerTilesStatus;
