import { LibraryLayerItemType } from 'types/Layers/LibraryLayerItemType';
import LibraryLayerTreeItem from 'types/Layers/LibraryLayerTreeItem';

export const SLIDE_TYPE = 'Blackbird.Business.Dtos.Presentation.SlideDto, Blackbird.Business';
export const SLIDE_FOLDER_TYPE =
    'Blackbird.Business.Dtos.Presentation.SlideFolderDto, Blackbird.Business';

export const LibraryNamespaces = Object.freeze({
    FOLDER: 'Blackbird.Business.Models.Library.LibraryFolder, Blackbird.Business',
    KMLLAYER: 'Blackbird.Business.Models.Library.LibraryLayer, Blackbird.Business',
    PROPERTYVIEW: 'Blackbird.Business.Models.Library.LibraryPropertyViewDto, Blackbird.Business',
    PROPERTYSEARCH:
        'Blackbird.Business.Models.Library.LibraryPropertySearchDto, Blackbird.Business',
    MAPITLAYER: 'Blackbird.Business.Models.Library.LibraryMapItLayer, Blackbird.Business',
    IMAGE: 'Blackbird.Business.Models.Library.LibraryImageModel, Blackbird.Business',
    PINNED_PROPERTY:
        'Blackbird.Business.Models.Library.LibraryPinnedProperties, Blackbird.Business',
    UNPINNED_CONTROLLER:
        'Blackbird.Business.Models.Library.LibraryUnpinnedController, Blackbird.Business',
    SUBMARKET_BOUNDARIES:
        'Blackbird.Business.Models.Library.LibrarySubmarketStatistics, Blackbird.Business',
    AMENITY: 'Blackbird.Business.Models.Library.LibraryAmenityDto, Blackbird.Business',
    OVERLAY: 'Blackbird.Business.Models.Library.LibraryOverlayDto, Blackbird.Business',
    CSV: 'Blackbird.Business.Models.Library.LibraryCsvLayerDto, Blackbird.Business',
    OSM_HIGHLIGHT_SET:
        'Blackbird.Business.Models.Library.LibraryOsmHighlightSet, Blackbird.Business',
    ANNOTATION_COLLECTION:
        'Blackbird.Business.Models.Library.LibraryQuickLayerDto, Blackbird.Business',
    WIDGET_LAYER: 'Blackbird.Business.Models.Library.LibraryWidgetLayer, Blackbird.Business',
    MEDIA_LAYER: 'Blackbird.Business.Models.Library.LibraryMediaLayer, Blackbird.Business',
});

export const itemTypeToLibraryNamespace = (type: LibraryLayerItemType) => {
    switch (type) {
        case 'Folder':
            return LibraryNamespaces.FOLDER;
        case 'Layer':
        case 'KmlLayer':
            return LibraryNamespaces.KMLLAYER;
        case 'Image':
            return LibraryNamespaces.IMAGE;
        case 'MapItLayer':
            return LibraryNamespaces.MAPITLAYER;
        case 'PinnedProperties':
            return LibraryNamespaces.PINNED_PROPERTY;
        case 'UnpinnedController':
            return LibraryNamespaces.UNPINNED_CONTROLLER;
        case 'PropertyView':
            return LibraryNamespaces.PROPERTYVIEW;
        case 'PropertySearch':
            return LibraryNamespaces.PROPERTYSEARCH;
        case 'SubmarketStatistics':
            return LibraryNamespaces.SUBMARKET_BOUNDARIES;
        case 'Amenity':
            return LibraryNamespaces.AMENITY;
        case 'Overlay':
            return LibraryNamespaces.OVERLAY;
        case 'CsvLayer':
            return LibraryNamespaces.CSV;
        case 'OsmHighlightSet':
            return LibraryNamespaces.OSM_HIGHLIGHT_SET;
        case 'QuickLayer':
            return LibraryNamespaces.ANNOTATION_COLLECTION;
        case 'WidgetLayer':
            return LibraryNamespaces.WIDGET_LAYER;
        case 'MediaLayer':
            return LibraryNamespaces.MEDIA_LAYER;
        default:
            throw new Error(`Unknown image type: ${type}`);
    }
};

export const LAYER_SORT_FIELDS = [
    { label: 'A-Z', value: 'name-asc' },
    { label: 'Z-A', value: 'name-desc' },
    { label: 'Last Updated', value: 'modifiedDate' },
];

export const LAYER_FILTER_TYPES = [
    { label: 'My content', value: 'mapit-my' },
    { label: 'Group content', value: 'mapit-group' },
    { label: 'GeoLibrary', value: 'mapit-geolib' },
    { label: 'Organizational', value: 'mapit-org' },
];

export interface LayerContextMenuItem {
    key?: string;
    type?: 'divider';
    label?: string | JSX.Element | ((libraryItem: LibraryLayerTreeItem) => JSX.Element | string);
    visibleLayers?: LibraryLayerItemType[];
    visible?: (libraryItem: LibraryLayerTreeItem) => boolean;
}

export const kmlStyleTypes: Record<number, string> = {
    0: 'polyStyle',
    1: 'lineStyle',
    2: 'iconStyle',
    3: 'labelStyle',
    4: 'overlayStyle',
    5: 'balloonStyle',
};

export const RIGHT_SIDEBAR_WIDTH = 400;
export const HEADER_HEIGHT = 56;
