import { useMemo, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from '@jll/react-ui-components';

import { canEditPresentation, Presentation } from 'api/presentationApi';
import { PresentationRenameModal } from './RenamePresentationModal';
import usePresentationActions from './usePresentationActions';

export default function SavePresentationButton({
    presentation,
    buttonType = 'primary',
    buttonText = 'Save Presentation',
}: {
    presentation: Presentation;
    buttonType?: 'link' | 'text' | 'default' | 'dashed' | 'primary' | undefined;
    buttonText?: string;
}) {
    const [showRenameModal, setShowRenameModal] = useState(false);
    const { savePresentation } = usePresentationActions();

    const menuConfig = useMemo(() => {
        const menuEvents: { [key: string]: () => void } = {
            save: () => {
                savePresentation(presentation.id, presentation.presentationName);
            },
            saveAs: () => {
                setShowRenameModal(true);
            },
        };

        const menu: MenuProps = {
            onClick: (e) => {
                menuEvents[e.key]();
            },

            items: [
                {
                    key: 'save',
                    label: 'Save',
                    disabled: !presentation?.id || !canEditPresentation(presentation),
                },
                { key: 'saveAs', label: 'Save As' },
            ],
        };

        return menu;
    }, [presentation, savePresentation]);

    return (
        <>
            <Dropdown menu={menuConfig} trigger={['click']} disabled={showRenameModal}>
                <Button type={buttonType}>
                    {buttonText} <DownOutlined style={{ scale: '1 0.8' }} />
                </Button>
            </Dropdown>

            <PresentationRenameModal
                presentationName={presentation.presentationName}
                open={showRenameModal}
                onOk={(presentationName) => {
                    setShowRenameModal(false);
                    savePresentation(0, presentationName);
                }}
                onCancel={() => {
                    setShowRenameModal(false);
                }}
            />
        </>
    );
}
