import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Row, Typography } from '@jll/react-ui-components';

import MarketSelect, { MarketSummaryOption } from 'components/MarketSelect';
import { selectMarketList } from 'store/marketListSlice';
import { useAppSelector } from 'types/hooks';

import styles from './QuickStart.module.scss';

const { Text, Title } = Typography;

export default function QuickStart(): ReactElement {
    const markets = useAppSelector(selectMarketList);
    const navigate = useNavigate();
    const handleExploreClicked = () => {
        navigate('/presentations');
    };
    const handleOnMarketChange = (marketSummaryOption: MarketSummaryOption) => {
        const market = markets?.find((market) => market.id === marketSummaryOption.value);
        const path = market?.propertyProviderId
            ? `/index.html?MarketId=${marketSummaryOption.key}`
            : `/market/${marketSummaryOption.key}`;
        window.location.href = path;
    };

    return (
        <div className={styles.quickStart}>
            <Text className={styles.subTitle}>Quick Start</Text>
            <Title className={styles.title} level={4}>
                Blackbird&apos;s Off The Shelf Content
            </Title>
            <Text className={styles.description}>
                Start straight away with our pre-built presentations available for you to use and
                customize.
            </Text>

            <Row justify='center' wrap={false} align='middle' style={{ marginTop: '24px' }}>
                <MarketSelect
                    id='quick-start__market-select'
                    getPopupContainer={() =>
                        document.getElementById('main-content') || document.body
                    }
                    placeholder={'Open Off The Shelf Presentation'}
                    size='large'
                    showSearch={true}
                    onChange={(value) => handleOnMarketChange(value as MarketSummaryOption)}
                    className={styles.marketSelect}
                />
                <Text
                    id='quick-start__explore-blackbird-library'
                    onClick={handleExploreClicked}
                    style={{
                        cursor: 'pointer',
                        color: 'white',
                        whiteSpace: 'nowrap',
                        marginLeft: '24px',
                    }}
                >
                    Explore Blackbird Library
                    <ArrowRightOutlined style={{ marginLeft: '16px' }} />
                </Text>
            </Row>
        </div>
    );
}
