import { CSSProperties, useEffect, useState } from 'react';
import { Color, ColorPicker, Icon, Row } from '@jll/react-ui-components';
import { useDebouncedCallback } from 'use-debounce';

import styles from './ColorPicker.module.scss';

import ColorPickerIcon from 'images/color-picker.svg';

export const presetColors = [
    'rgb(64,121,141)', //#40798D
    'rgb(209,185,167)', //#D1B9A7,
    'rgb(145,89,145)', //#915991
    'rgb(170,188,244)', //#AABCF4
    'rgb(125,111,100)', //#7D6F64
    'rgb(208,181,208)', //#D0B5D0
    'rgb(102,117,121)', //#667579
    'rgb(208,79,105)', //#D04F69
    'rgb(226,134,85)', //#E28655
    'rgb(88,143,104)', //#588F68
];

const defaultColor = 'rgba(64,121,141,1)';
const defaultOpacity = 0.8;

interface ColorPickerProps {
    color?: string;
    style?: CSSProperties;
    onChange: (color: string, opacity: number) => void;
}

const colorPickerStyles: CSSProperties = {
    margin: 0,
};

export const ColorPickerComponent = ({ color, style, onChange }: ColorPickerProps) => {
    const [selectedColor, setSelectedColor] = useState(color || defaultColor);

    const mergedStyle = {
        ...colorPickerStyles,
        ...style,
    };

    useEffect(() => {
        return () => {
            setSelectedColor(defaultColor);
        };
    }, []);

    const handleColorChange = useDebouncedCallback(
        (color: Color | string) => {
            const newColor =
                typeof color === 'object' ? color.toRgbString() : color ?? defaultColor;
            const opacity =
                typeof color === 'object' ? parseFloat(color.toRgb().a.toFixed(2)) : defaultOpacity;
            setSelectedColor(newColor);
            onChange(newColor, opacity);
        },
        250,
        {
            maxWait: 500,
        }
    );

    return (
        <Row gutter={16} style={mergedStyle}>
            <div className={styles.colorPicker}>
                {presetColors.map((presetColor, index) => (
                    <div
                        key={`${presetColor}-${index}`}
                        className={`${styles.swatch} ${
                            selectedColor === presetColor ? styles.selected : ''
                        }`}
                        style={{ background: presetColor }}
                        onClick={() => handleColorChange(presetColor)}
                    >
                        {selectedColor === presetColor && (
                            <Icon
                                name='check'
                                style={{
                                    color: '#FFF',
                                    fontSize: '14px',
                                }}
                            />
                        )}
                    </div>
                ))}
                <ColorPicker value={selectedColor} onChange={handleColorChange}>
                    <ColorPickerIcon id='color-picker-icon' style={{ fill: selectedColor }} />
                </ColorPicker>
            </div>
        </Row>
    );
};
