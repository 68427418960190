import { ReactElement, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GlobalOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Badge,
    Button,
    Col,
    Dropdown,
    MenuProps,
    Row,
    Space,
    Tooltip,
} from '@jll/react-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { selectIsAdminUser } from 'store/userSlice';
import { useAppSelector } from 'types/hooks';
import NewPresentationModal from './../presentations/NewPresentationModal';

import styles from './LeftPanel.module.scss';

const links = [
    {
        to: '/',
        title: 'Dashboard',
        id: 'left-panel__dashboard',
    },
    {
        to: '/presentations/owner',
        title: 'My Presentations',
        id: 'left-panel__my-presentations',
    },
    {
        to: '/presentations/sharedWithMe',
        title: 'Shared With Me',
        id: 'left-panel__shared-with-me',
    },
    {
        to: '/presentations/public',
        title: 'Blackbird Library',
        id: 'left-panel__public-library',
    },
    {
        to: '/presentations/favorite',
        title: 'My Favorites',
        id: 'left-panel__my-favorites',
    },
];

const blackbirdTrainingLink =
    'https://jll2.sharepoint.com/teams/Blackbird/SitePages/Blackbird-Training.aspx';
const releaseNotesLink = 'https://jll2.sharepoint.com/teams/Blackbird/SitePages/Release-notes.aspx';

export default function LeftPanel(): ReactElement {
    const [showNewPresentationModal, setShowNewPresentationModal] = useState(false);
    const isAdminUser = useAppSelector(selectIsAdminUser);
    const location = useLocation();
    const { launchButtonUpdate } = useFlags();

    const CreatePresentationPanel = launchButtonUpdate
        ? DropdownCreatePresentationPanel
        : LegacyCreatePresentationPanel;

    return (
        <div className={styles.leftPanel}>
            <div style={{ margin: '40px 24px', justifyContent: 'center', display: 'flex' }}>
                <CreatePresentationPanel
                    openCreateLegacyPresentationModal={() => setShowNewPresentationModal(true)}
                />
            </div>

            <NewPresentationModal
                visible={showNewPresentationModal}
                onClose={() => setShowNewPresentationModal(false)}
            />
            <div className={styles.contentSection}>
                {links.map((nav) => (
                    <Link
                        id={nav.id}
                        key={nav.id}
                        className={location.pathname === nav.to ? styles.selected : ''}
                        to={nav.to}
                    >
                        {nav.title}
                    </Link>
                ))}
            </div>
            <div className={styles.contentSection}>
                {isAdminUser && (
                    <Link id='left-panel__admin-link' to='/admin'>
                        Admin
                    </Link>
                )}
                <a
                    id='left-panel__blackbird-training-link'
                    href={blackbirdTrainingLink}
                    target='_blank'
                    rel='noreferrer'
                >
                    Blackbird Academy
                </a>
                <a id='left-panel__mail-link' href={'mailto:Blackbird@am.jll.com'}>
                    Contact Us
                </a>
                <a
                    id='left-panel__blackbird-version'
                    href={releaseNotesLink}
                    target='_blank'
                    rel='noreferrer'
                >
                    <Row>
                        <Col span={18}>
                            <span>Release Notes</span>
                        </Col>
                        <Col span={6}>
                            <span className={styles.tag}>v{window.VERSION}</span>
                        </Col>
                    </Row>
                </a>
            </div>
        </div>
    );
}

interface CreatePresentationPanelProps {
    openCreateLegacyPresentationModal: () => void;
}
function LegacyCreatePresentationPanel(props: CreatePresentationPanelProps): JSX.Element {
    const { globalMarkets: showGlobalMarketButton = false } = useFlags();
    const openGlobalBlackbird = () => {
        window.location.href = 'explore/global';
    };

    return (
        <Space.Compact size='large'>
            <Button
                id='left-panel__create-presentation-button'
                size='large'
                type='primary'
                onClick={props.openCreateLegacyPresentationModal}
                icon={<PlusOutlined />}
            >
                Create New Presentation
            </Button>
            {showGlobalMarketButton && (
                <Badge.Ribbon text='New' style={{ top: '-16px', fontSize: '12px', zIndex: 2 }}>
                    <Tooltip title='Try the New Blackbird' placement='right'>
                        <Button
                            id='left-panel__try-new-blackbird'
                            size='large'
                            style={{ borderLeft: '1px solid #999' }}
                            type='primary'
                            onClick={openGlobalBlackbird}
                            icon={<GlobalOutlined />}
                        />
                    </Tooltip>
                </Badge.Ribbon>
            )}
        </Space.Compact>
    );
}

function DropdownCreatePresentationPanel(props: CreatePresentationPanelProps): JSX.Element {
    const menuItems: MenuProps['items'] = [
        { key: '1', label: <Link to='/explore/northamerica'>North America Presentation</Link> },
        { key: '2', label: <Link to='/explore/global'>Global Presentation</Link> },
        {
            key: '3',
            label: 'Legacy Presentation',
            onClick: props.openCreateLegacyPresentationModal,
        },
    ];

    return (
        <Dropdown menu={{ items: menuItems }} trigger={['click']}>
            <Button
                id='left-panel__create-presentation-button'
                size='large'
                type='primary'
                icon={<PlusOutlined />}
                block
            >
                Create New Presentation
            </Button>
        </Dropdown>
    );
}
