import { LayerListItemModel } from 'components/layerWindow/LayerListItem';
import SharedState from 'enums/SharedState';
import endpoints from 'utils/apiClient/endpoints';
import { simpleDownload } from 'utils/fileUtils';

export interface FetchLayerResult {
    results: LayerListItemModel[];
    total: number;
}

export interface KmlSearchPayload {
    searchQuery: string;
    sortBy: string;
    sortOrder: string;
    offset: number;
    limit: number;
    archive: number;
    sharedState?: SharedState;
}

export const downloadKml = async (layerId: number, title: string): Promise<void> => {
    const response = await endpoints.kml.download.get({
        templateValues: {
            layerId,
        },
    });
    const kmlUrl = await response.text();
    simpleDownload(kmlUrl, title);
};

export const requestKmlRegeneration = async (layerId: number): Promise<void> => {
    await endpoints.kml.regenerate.post({
        templateValues: {
            layerId,
        },
    });
};

export const fetchKmlLayers = async (payload: KmlSearchPayload): Promise<FetchLayerResult> => {
    const response = await endpoints.kml.search.get({
        queryParameters: { ...payload },
    });
    return await response.json();
};
