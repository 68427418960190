import { Button, Row, Space, Typography } from '@jll/react-ui-components';

import { setExportId, setExportProgress, setExportType } from 'store/exportSlice';
import { useAppDispatch } from 'types/hooks';
import { nanoid } from 'utils/idUtils';

export default function ExportSlidesSection({ onExport }: { onExport: () => void }) {
    const dispatch = useAppDispatch();

    // TODO: We need to make sure to notify the user that their presentation needs to be saved before they can export

    const handleExportPresentation = async (format: 'PDF' | 'Powerpoint') => {
        dispatch(setExportId(nanoid()));
        dispatch(setExportType(format));
        dispatch(setExportProgress(0));
        onExport();
    };

    return (
        <>
            <Row>
                <Typography.Text
                    style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        marginBottom: 5,
                    }}
                >
                    Export All Slides
                </Typography.Text>
            </Row>
            <Row>
                <Space>
                    <Button onClick={() => handleExportPresentation('Powerpoint')}>
                        Export As Powerpoint
                    </Button>
                    <Button onClick={() => handleExportPresentation('PDF')}>Export As PDF</Button>
                </Space>
            </Row>
        </>
    );
}
