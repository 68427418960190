import endpoints from '../utils/apiClient/endpoints';

export async function downloadCsv(marketId: number): Promise<Response> {
    return await endpoints.properties.download.get({
        templateValues: {
            marketId,
        },
    });
}

export async function downloadKmz(marketId: number, withPopups: boolean): Promise<Response> {
    return await endpoints.properties.downloadKmz.get({
        templateValues: {
            marketId: marketId,
        },
        queryParameters: {
            withPopups: withPopups.toString(),
        },
    });
}
