import { useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Select, Typography } from '@jll/react-ui-components';
import { uniq } from 'lodash';

import { ShareInfoDtoShareEntry, shareWithUsers } from 'api/presentationApi';
import { searchUser, UserSummary } from 'api/userApi';
import {
    closePresentationInternalInviteModal,
    fetchPresentationShareStatistics,
    selectPresentationInternalInviteModalOpen,
    selectPresentationShareStatistics,
} from 'store/presentationShareSlice';
import { selectCurrentUser } from 'store/userSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import InternalShareList from './InternalShareList';

const accessTypes = [
    {
        label: 'View',
        value: 1,
    },
    {
        label: 'Edit',
        value: 2,
    },
];
export function InternalShareInviteModal({
    presentationId,
    presentationName,
    sharedUsers,
}: {
    presentationId: number;
    presentationName: string;
    sharedUsers: ShareInfoDtoShareEntry[];
}) {
    const dispatch = useAppDispatch();
    const open = useAppSelector(selectPresentationInternalInviteModalOpen);
    const user = useAppSelector(selectCurrentUser);
    const presentationShareStatistics = useAppSelector(selectPresentationShareStatistics);
    const [usersAdded, setUsersAdded] = useState<UserSummary[]>([]);
    const [users, setUsers] = useState<UserSummary[]>([]);
    const [accessType, setAccessType] = useState<number>(1);

    const goBack = () => {
        dispatch(closePresentationInternalInviteModal());
    };

    const handleSearch = async (search: string) => {
        if (search.length > 2) {
            const _users = await searchUser(search, 5);
            setUsers(_users);
        } else {
            setUsers([]);
        }
    };

    const handleShare = async () => {
        const newUsers = usersAdded.map((u) => u.email);
        const level1Access = presentationShareStatistics
            .filter((u) => u.user.accessType === 1)
            .map((u) => u.user.email);

        const level2Access = presentationShareStatistics
            .filter((u) => u.user.accessType === 2)
            .map((u) => u.user.email);

        if (accessType === 1) {
            level1Access.push(...newUsers);
        } else {
            level2Access.push(...newUsers);
        }
        await shareWithUsers(presentationId, {
            id: presentationId,
            level1Access: level1Access.join(';'),
            level2Access: level2Access.join(';'),
            presentationName,
            updatedBy: user.userPrincipalName,
        });
        dispatch(closePresentationInternalInviteModal());
        dispatch(fetchPresentationShareStatistics(presentationId));
    };

    const handleChange = (value: string[]) => {
        const _users = users.filter((u) => value.includes(u.email.toString()));
        setUsersAdded([...usersAdded, ..._users]);
    };

    return (
        <Modal
            title={
                <>
                    <Button size='large' type='text' onClick={goBack}>
                        <ArrowLeftOutlined />
                    </Button>
                    <Typography.Text
                        style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                        }}
                    >
                        Invite JLL Users
                    </Typography.Text>
                </>
            }
            open={open}
            width='600px'
            centered
            footer={null}
            onCancel={goBack}
        >
            <Row gutter={8} style={{ marginBottom: 20 }}>
                <Col span={20}>
                    <Select
                        showSearch
                        placeholder='Search for people and groups'
                        onSearch={handleSearch}
                        onChange={handleChange}
                        mode='multiple'
                        allowClear
                        style={{ width: '100%' }}
                        notFoundContent={null}
                        options={uniq([...usersAdded, ...users])}
                        fieldNames={{ label: 'email', value: 'email' }}
                        filterOption={false}
                    />
                </Col>
                <Col span={4}>
                    <Select
                        options={accessTypes}
                        style={{ width: '100%' }}
                        value={accessType}
                        onChange={(v) => setAccessType(v)}
                    />
                </Col>
            </Row>
            <InternalShareList
                sharedUsers={sharedUsers}
                presentationId={presentationId}
                presentationName={presentationName}
            />
            <Row justify='end' style={{ marginTop: 20 }}>
                <Col>
                    <Button type='primary' onClick={handleShare}>
                        Share
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}
