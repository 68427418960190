import { DaylightViewSlice } from 'store/daylightWidgetSlice';
import { LegendState } from 'store/featuresSlice';
import { StreetMapViewSlice } from 'store/streetMapViewSlice';
import { WeatherState } from 'store/weatherWidgetSlice';

export enum SlideImageType {
    Default,
    ImageOverlay,
    WebsiteOverlay,
    SlideImageType,
}

export interface FocusedSlide {
    key: string;
    renaming?: boolean;
    scrollTo?: boolean;
}

export interface SlideItemBase {
    id?: number;
    key: string;
    name: string;
    selected?: boolean;
}

export interface Slide extends SlideItemBase {
    slideItemType: 'Slide';
    imageType: SlideImageType;
    folderId?: string;
    active: boolean;
    hidden?: boolean;
    image?: string;
    cloudinaryImageId: string;
    state?: SlideState;
}

export interface SlideFolder extends SlideItemBase {
    slideItemType: 'Folder';
    expanded: boolean;
    children: Slide[];
}

interface SlideStateLayerChild {
    visible: boolean;
}

export interface SlideStateLayer extends SlideStateLayerChild {
    id?: number | string;
    type?: string;
    isFullScreen?: boolean;
    children?: SlideStateLayerChild[];
}

export interface SlideState {
    camera?: string;
    legend?: LegendState;
    streetView?: StreetMapViewSlice;
    daylightView?: DaylightViewSlice;
    weather?: WeatherState;
    layers?: SlideStateLayer[];
    layerIds?: string[];
    mapFeatures?: object;
    basemap?: string;
}

export type SlideItem = Slide | SlideFolder;

export enum ModifierType {
    Ctrl = 'ctrl',
    Shift = 'shift',
}

export interface SlideItemSaveStateBase {
    $type: string;
    id?: number;
    name: string;
    sequence: number;
}

export interface SlideSaveState extends SlideItemSaveStateBase {
    active: boolean;
    hidden?: boolean;
    folderId?: string;
    image?: string;
    cloudinaryImageId: string;
    state: string;
    slideItemType: 'Slide';
}

export interface SlideFolderSaveState extends SlideItemSaveStateBase {
    expanded: boolean;
    children: (SlideSaveState | SlideFolderSaveState)[];
    slideItemType: 'Folder';
}

export type SlideItemSaveState = SlideSaveState | SlideFolderSaveState;

export interface SlideSettings {
    showSlideName: boolean;
}
