import { Col, message, Row, Space, Switch, Typography } from '@jll/react-ui-components';

import { publishPresentation, revokePublish } from 'api/presentationApi';
import { setPresentationPublished } from 'store/presentationSlice';
import { useAppDispatch } from 'types/hooks';

export default function PublishSection({
    presentationId,
    published,
}: {
    presentationId: number;
    published: boolean;
}) {
    const dispatch = useAppDispatch();
    const handlePublishPresentation = async (checked: boolean) => {
        try {
            if (checked) {
                await publishPresentation(presentationId);
                dispatch(setPresentationPublished(true));
                message.success('Presentation published to the company');
            } else {
                await revokePublish(presentationId);
                dispatch(setPresentationPublished(false));
                message.success('Presentation access revoked');
            }
        } catch (e) {
            message.error('Error. Please, try again.');
        }
    };

    return (
        <>
            <Row
                style={{
                    marginBottom: '5px',
                }}
            >
                <Typography.Text
                    style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }}
                >
                    Publish company-wide
                </Typography.Text>
            </Row>
            <Row justify='space-between'>
                <Col>
                    <Typography.Text>
                        Publish presentation and add it to the Blackbird library
                    </Typography.Text>
                </Col>
                <Col style={{ display: 'flow', alignItems: 'center', gap: '8px' }}>
                    <Space>
                        <Switch
                            checked={published}
                            onClick={(checked) => handlePublishPresentation(checked)}
                        />
                        <Typography.Text>Publish</Typography.Text>
                    </Space>
                </Col>
            </Row>
        </>
    );
}
