export const SEARCH_BUTTON = 'Search';
export const MORE_FILTERS = 'More Filters';
export type SearchItemTypes = 'properties' | 'availabilities' | 'leaseComps' | 'sales';

export const industries = [
    'Aerospace & Defense',
    'Construction, Machinery & Materials',
    'Consumer Products',
    'Consumer Services',
    'Education',
    'Energy & Utilities',
    'Finance',
    'Food & Beverage',
    'Government',
    'Health',
    'Logistics & Distribution',
    'Media',
    'Nonprofit',
    'Professional and Business Services',
    'Retail',
    'Technology',
    'Travel & Recreation',
];

export const buildingClassAllowed = ['Class A', 'Class B', 'Class C', 'Trophy'];

export const checkboxOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];
